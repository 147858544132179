import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import AuthProvider from "./Providers/Auth";
import Apollo from "./Providers/Apollo";
import Theme from "./Providers/Theme";
import Routes from "./Routes/Routes";
import "./App.css";

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <Apollo>
            <Theme>
              <CssBaseline />
              <Routes />
            </Theme>
          </Apollo>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
