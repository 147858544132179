import { useQuery } from "@apollo/client";
import { useParams, useNavigate, Route, Routes } from "react-router-dom";
import { Typography } from "@mui/material";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";

import LeadsDrawer from "./Lead";
import Loader from "../../Components/Loader";
import { GET_FORM_QUESTIONS, GET_FLAT_LEADS } from "../../graphql";
import {
  GetFormQuestionsQuery,
  GetFlatLeadsQuery,
} from "../../generated/graphql";
import { formatDistance } from "date-fns";

const Leads = () => {
  const { formId } = useParams<{ formId: string }>();
  const navigate = useNavigate();
  const {
    error: leadsError,
    loading: leadsLoading,
    data: leadsData,
  } = useQuery<GetFlatLeadsQuery>(GET_FLAT_LEADS, {
    variables: { id: formId },
  });

  const {
    error: questionsError,
    loading: questionsLoading,
    data: questionsData,
  } = useQuery<GetFormQuestionsQuery>(GET_FORM_QUESTIONS, {
    variables: { id: formId },
  });

  if (questionsLoading || leadsLoading) return <Loader />;

  if (questionsError || leadsError)
    return <pre>{JSON.stringify({ questionsError, leadsError }, null, 2)}</pre>;

  const columns = questionsData?.forms_blocks.map((block) => ({
    field: block.id,
    headerName: (block.props.label || block.label || block.id)
      .replace(/<[^>]*>?/gm, " ")
      .replace(/\s+/, " ")
      .trim(),
    width: 200,
    renderCell: (params: any) => {
      return (
        <div>
          {params.value?.option_label && (
            <Typography color="text.secondary" variant="caption">
              {params.value?.option_label
                .replace(/<[^>]*>?/gm, " ")
                .replace(/\s+/, " ")
                .trim()}
            </Typography>
          )}
          {params.value?.value && (
            <Typography variant="body2">
              {params.value?.value?.value?.toString()}
            </Typography>
          )}
        </div>
      );
    },
  })) as GridColDef[];

  columns.unshift({
    field: "created_at",
    headerName: "Created",
    width: 150,
    renderCell: (params: any) => {
      return (
        <div>
          <Typography color="text.secondary" variant="caption">
            {formatDistance(new Date(params.value), new Date(), {
              addSuffix: true,
            })}
          </Typography>
          <Typography variant="body2">
            {new Date(params.value).toLocaleString()}
          </Typography>
        </div>
      );
    },
  });

  const rows = leadsData?.forms_forms_by_pk?.flat_leads.map((lead) => {
    let row: any = {};
    columns?.map((column: any) => {
      return (row[column.field] = lead.answers[column.field]);
    });
    return { ...row, id: lead.lead_id, created_at: lead.created_at };
  }) as GridRowsProp;

  return (
    <>
      <DataGrid
        style={{
          height: "100%",
          background: "white",
          borderTop: 0,
          cursor: "pointer",
        }}
        rows={rows}
        columns={columns}
        onRowClick={(params) => {
          navigate(`/forms/${formId}/leads/${params.row.id}`);
        }}
      />
      <Routes>
        <Route
          path=":leadId"
          element={
            <LeadsDrawer
              questions={questionsData?.forms_blocks}
              onClose={() => navigate(`/forms/${formId}/leads`)}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Leads;
