import React, { useState, useEffect } from "react";
import {
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatClear,
} from "@mui/icons-material";
import { SketchPicker } from "react-color";
import { Button, ButtonGroup, ClickAwayListener, Stack } from "@mui/material";

const FormatButtons = () => {
  const [showPicker, togglePicker] = useState(false);
  const [color, setColor] = useState("#000000");

  useEffect(() => {
    const handler = () => {
      const col = document
        .getSelection()
        ?.focusNode?.parentElement?.getAttribute("color");
      setColor(col || "#000000");
    };
    document.addEventListener("selectionchange", handler);
    handler();
    return () => {
      document.removeEventListener("selectionchange", handler);
      console.log("I was destroyed");
    };
  }, []);

  return (
    <Stack my={2} direction="column" alignItems="left" spacing={1}>
      {[
        [
          { cmd: "justifyLeft", name: <FormatAlignLeft />, arg: undefined },
          { cmd: "justifyCenter", name: <FormatAlignCenter />, arg: undefined },
          { cmd: "justifyRight", name: <FormatAlignRight />, arg: undefined },
        ],
        [
          { cmd: "bold", name: <FormatBold />, arg: undefined },
          { cmd: "italic", name: <FormatItalic />, arg: undefined },
          { cmd: "underline", name: <FormatUnderlined />, arg: undefined },
          { cmd: "removeFormat", name: <FormatClear />, arg: undefined },
        ],
        [
          { cmd: "formatBlock", arg: "h1", name: "h1" },
          { cmd: "formatBlock", arg: "h2", name: "h2" },
          { cmd: "formatBlock", arg: "h3", name: "h3" },
          { cmd: "formatBlock", arg: "p", name: "p" },
        ],
      ].map((group, i) => (
        <ButtonGroup size="small" key={i}>
          {group.map(({ cmd, name, arg }, i) => (
            <Button
              key={i}
              style={{ width: 50 }}
              onMouseDown={(evt) => {
                evt.preventDefault(); // Avoids loosing focus from the editable area
                document.execCommand(cmd, false, arg); // Send the command to the browser
              }}
            >
              {name || cmd}
            </Button>
          ))}
        </ButtonGroup>
      ))}
      <div style={{ position: "relative" }}>
        <Button
          onClick={() => togglePicker(!showPicker)}
          style={{ backgroundColor: color, width: 100 }}
          size="small"
        >
          &nbsp;
        </Button>
        {showPicker && (
          <ClickAwayListener onClickAway={() => togglePicker(false)}>
            <div
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => e.preventDefault()}
              style={{ position: "absolute", top: 40, left: 0, zIndex: 10 }}
            >
              <SketchPicker
                color={color}
                onChange={(color) => setColor(color.hex)}
                onChangeComplete={(color) =>
                  document.execCommand(
                    "foreColor",
                    false,
                    color.hex.substring(1)
                  )
                }
              />
            </div>
          </ClickAwayListener>
        )}
      </div>
    </Stack>
  );
};

export default FormatButtons;
