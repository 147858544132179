import React, { useState, useEffect, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { Alert, Box, Button, Typography, TextField } from "@mui/material";
import { useParams } from "react-router-dom";

import { GET_FORM, UPDATE_OPTION } from "../graphql";

import {
  GetFormQuery,
  UpdateOptionMutation,
  UpdateOptionMutationVariables,
} from "../generated/graphql";

import FormatButtons from "./FormatButtons";

type FormType = NonNullable<GetFormQuery["forms_forms_by_pk"]>;
type Option = NonNullable<
  GetFormQuery["forms_forms_by_pk"]
>["steps"][0]["blocks"][0]["options"][0];

const OptionSettings: React.FC<{ form: FormType }> = ({ form }) => {
  const { optionId } = useParams<{ optionId: string }>();

  const option = useMemo(
    () =>
      form.steps
        .flatMap((step) => step.blocks)
        .flatMap((block) => block.options)
        .find((option) => option.id === optionId),
    [form, optionId]
  );

  const defaultValues = useMemo(
    () => ({
      id: option?.id,
      label: option?.label,
      icon: option?.icon,
      value: option?.value,
    }),
    [option]
  );

  const { handleSubmit, register, reset } = useForm<Option>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [updateOption] =
    useMutation<UpdateOptionMutation, UpdateOptionMutationVariables>(
      UPDATE_OPTION
    );

  const [mutationError, setMutationError] = useState(null);

  const refetchQueries = [{ query: GET_FORM, variables: { id: form?.id } }];

  const onSubmit = handleSubmit((data) => {
    setMutationError(null);
    const { id, ..._set } = data;
    updateOption({ variables: { id, _set }, refetchQueries }).catch((e) =>
      setMutationError(e.message)
    );
  });

  return (
    <>
      <Typography variant="h3">Edit option</Typography>
      <FormatButtons />
      <form onSubmit={onSubmit} autoComplete="off">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
          }}
        >
          {mutationError && <Alert severity="error">{mutationError}</Alert>}
          <TextField
            {...register("label", { required: true })}
            id="label"
            label="Label"
            size="small"
            margin="dense"
            fullWidth
          />
          <TextField
            {...register("icon", { required: false })}
            id="icon"
            label="Icon"
            size="small"
            margin="dense"
            fullWidth
          />
          <TextField
            {...register("value", { required: true })}
            id="value"
            label="Value"
            size="small"
            margin="dense"
            fullWidth
          />
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            disableElevation
            color="primary"
          >
            Save
          </Button>
          <Button fullWidth color="primary">
            Cancel
          </Button>
        </Box>
      </form>
    </>
  );
};

export default OptionSettings;
