import { gql } from "@apollo/client";

export const OPTION_FRAGMENT = gql`
  fragment OptionFragment on forms_options {
    id
    block_id
    created_at
    updated_at
    order
    label
    icon
    value
  }
`;

export const BLOCK_FRAGMENT = gql`
  ${OPTION_FRAGMENT}
  fragment BlockFragment on forms_blocks {
    id
    order
    created_at
    block_type_id
    label
    props
    options(order_by: { order: asc }) {
      id
      ...OptionFragment
    }
  }
`;

export const STEP_FRAGMENT = gql`
  fragment StepFragment on forms_steps {
    id
    form_id
    name
    order
    slug
    webhook_send
    webhook_url
    display_conditions {
      id
      block_id
      step_id
      created_at
      operator
      updated_at
      values
      block {
        id
        label
      }
    }
  }
`;

export const FORM_FRAGMENT = gql`
  fragment FormFragment on forms_forms {
    id
    name
    slug
    created_at
    updated_at
    created_by
    folder_id
    gtm_id
    header_image_url
    header_image_max_width
    header_image_max_height
    show_progress_bar
    head_code
    body_code
    is_archived
  }
`;

export const GET_FORMS = gql`
  ${FORM_FRAGMENT}
  query GetForms($slug: String = "%") {
    forms_forms(
      where: { slug: { _ilike: $slug } }
      order_by: { created_at: desc }
    ) {
      id
      ...FormFragment
      steps(order_by: { order: asc }) {
        id
      }
      leads_aggregate {
        aggregate {
          count
          max {
            created_at
          }
        }
      }
    }
    iam_folders {
      id
      name
      created_by
      created_at
    }
  }
`;

export const GET_FORM = gql`
  ${FORM_FRAGMENT}
  ${STEP_FRAGMENT}
  ${BLOCK_FRAGMENT}
  ${OPTION_FRAGMENT}
  query GetForm($id: uuid!) {
    forms_forms_by_pk(id: $id) {
      id
      ...FormFragment
      steps(order_by: { order: asc }) {
        id
        ...StepFragment
        blocks(order_by: { order: asc }) {
          id
          ...BlockFragment
          options(order_by: { order: asc }) {
            id
            ...OptionFragment
          }
        }
      }
      leads(limit: 10, order_by: { created_at: desc }) {
        id
        flat_lead {
          answers
        }
        answers {
          id
          block_id
          lead_id
          value_v2
        }
      }
    }
  }
`;

export const INSERT_FORM = gql`
  ${FORM_FRAGMENT}
  ${STEP_FRAGMENT}
  ${BLOCK_FRAGMENT}
  ${OPTION_FRAGMENT}
  mutation InsertForm($form: forms_forms_insert_input!) {
    insert_forms_forms_one(object: $form) {
      id
      ...FormFragment
      steps(order_by: { order: asc }) {
        id
        ...StepFragment
        blocks(order_by: { order: asc }) {
          id
          ...BlockFragment
          options(order_by: { order: asc }) {
            id
            ...OptionFragment
          }
        }
      }
    }
  }
`;

export const UPDATE_FORM = gql`
  ${FORM_FRAGMENT}
  mutation UpdateForm($id: uuid!, $_set: forms_forms_set_input = {}) {
    update_forms_forms_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      ...FormFragment
    }
  }
`;

export const DELETE_FORM = gql`
  mutation DeleteForm($id: uuid!) {
    delete_forms_forms_by_pk(id: $id) {
      id
    }
  }
`;

export const INSERT_STEP = gql`
  ${STEP_FRAGMENT}
  ${BLOCK_FRAGMENT}
  mutation InsertStep($step: forms_steps_insert_input!) {
    insert_forms_steps_one(object: $step) {
      id
      ...StepFragment
      blocks {
        id
        ...BlockFragment
      }
    }
  }
`;

export const UPDATE_STEP = gql`
  ${STEP_FRAGMENT}
  mutation UpdateStep($id: uuid!, $_set: forms_steps_set_input = {}) {
    update_forms_steps_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      ...StepFragment
    }
  }
`;

export const DELETE_STEP = gql`
  mutation DeleteStep($id: uuid!, $form_id: uuid!, $order: Int!) {
    delete_forms_steps_by_pk(id: $id) {
      id
      order
    }
    update_forms_steps(
      where: { form_id: { _eq: $form_id }, order: { _gt: $order } }
      _inc: { order: -1 }
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const REORDER_STEP = gql`
  mutation ReorderStep(
    $form_id: uuid!
    $step_id: uuid!
    $starting_position: Int!
    $ending_position: Int!
  ) {
    down: update_forms_steps(
      where: {
        form_id: { _eq: $form_id }
        order: { _gt: $starting_position, _lte: $ending_position }
      }
      _inc: { order: -1 }
    ) {
      returning {
        id
      }
      affected_rows
    }
    up: update_forms_steps(
      where: {
        form_id: { _eq: $form_id }
        order: { _lt: $starting_position, _gte: $ending_position }
      }
      _inc: { order: 1 }
    ) {
      returning {
        id
      }
      affected_rows
    }
    update_forms_steps_by_pk(
      pk_columns: { id: $step_id }
      _set: { order: $ending_position }
    ) {
      id
    }
  }
`;

export const INSERT_BLOCK = gql`
  ${BLOCK_FRAGMENT}
  ${OPTION_FRAGMENT}
  mutation InsertBlock(
    $block: forms_blocks_insert_input!
    $step_id: uuid!
    $order: Int!
  ) {
    update_forms_blocks(
      where: { step_id: { _eq: $step_id }, order: { _gte: $order } }
      _inc: { order: 1 }
    ) {
      affected_rows
    }
    insert_forms_blocks_one(object: $block) {
      id
      ...BlockFragment
    }
  }
`;

export const UPDATE_BLOCK = gql`
  mutation UpdateBlock($id: uuid!, $_set: forms_blocks_set_input = {}) {
    update_forms_blocks_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      label
      props
    }
  }
`;

export const DELETE_BLOCK = gql`
  mutation DeleteBlock($step_id: uuid!, $block_id: uuid!, $order: Int!) {
    delete_forms_blocks_by_pk(id: $block_id) {
      id
    }
    update_forms_blocks(
      where: { step_id: { _eq: $step_id }, order: { _gt: $order } }
      _inc: { order: -1 }
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const REORDER_BLOCK = gql`
  mutation ReorderBlock(
    $step_id: uuid!
    $block_id: uuid!
    $starting_position: Int!
    $ending_position: Int!
  ) {
    up: update_forms_blocks(
      where: {
        step_id: { _eq: $step_id }
        order: { _lt: $starting_position, _gte: $ending_position }
      }
      _inc: { order: 1 }
    ) {
      returning {
        id
      }
      affected_rows
    }
    down: update_forms_blocks(
      where: {
        step_id: { _eq: $step_id }
        order: { _gt: $starting_position, _lte: $ending_position }
      }
      _inc: { order: -1 }
    ) {
      returning {
        id
      }
      affected_rows
    }
    update_forms_blocks_by_pk(
      pk_columns: { id: $block_id }
      _set: { order: $ending_position }
    ) {
      id
    }
  }
`;

export const INSERT_OPTION = gql`
  mutation InsertOption(
    $option: forms_options_insert_input!
    $block_id: uuid!
    $order: Int!
  ) {
    update_forms_options(
      where: { block_id: { _eq: $block_id }, order: { _gte: $order } }
      _inc: { order: 1 }
    ) {
      affected_rows
    }
    insert_forms_options_one(object: $option) {
      id
    }
  }
`;

export const UPDATE_OPTION = gql`
  mutation UpdateOption($id: uuid!, $_set: forms_options_set_input = {}) {
    update_forms_options_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export const REORDER_OPTION = gql`
  mutation ReorderOption(
    $block_id: uuid!
    $option_id: uuid!
    $starting_position: Int!
    $ending_position: Int!
  ) {
    up: update_forms_options(
      where: {
        block_id: { _eq: $block_id }
        order: { _lt: $starting_position, _gte: $ending_position }
      }
      _inc: { order: 1 }
    ) {
      returning {
        id
      }
      affected_rows
    }
    down: update_forms_options(
      where: {
        block_id: { _eq: $block_id }
        order: { _gt: $starting_position, _lte: $ending_position }
      }
      _inc: { order: -1 }
    ) {
      returning {
        id
      }
      affected_rows
    }
    update_forms_options_by_pk(
      pk_columns: { id: $option_id }
      _set: { order: $ending_position }
    ) {
      id
    }
  }
`;

export const DELETE_OPTION = gql`
  mutation DeleteOption($option_id: uuid!, $block_id: uuid!, $order: Int!) {
    delete_forms_options_by_pk(id: $option_id) {
      id
    }
    update_forms_options(
      where: { block_id: { _eq: $block_id }, order: { _gt: $order } }
      _inc: { order: -1 }
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const GET_BLOCKS_VALUES = gql`
  query GetBlocksValues($id: uuid!) {
    forms_forms_by_pk(id: $id) {
      id
      steps(order_by: { order: asc }) {
        order
        blocks(order_by: { order: asc }) {
          id
          label
          options {
            value
          }
          answers(distinct_on: value) {
            value
          }
        }
      }
    }
  }
`;

export const INSERT_DISPLAY_CONDITION = gql`
  mutation InsertDisplayCondition(
    $block_id: uuid!
    $step_id: uuid!
    $values: jsonb!
    $operator: String!
  ) {
    insert_forms_display_conditions_one(
      object: {
        block_id: $block_id
        step_id: $step_id
        values: $values
        operator: $operator
      }
    ) {
      id
      operator
      values
    }
  }
`;

export const DELETE_DISPLAY_CONDITION = gql`
  mutation DeleteDisplayCondition($id: uuid = "") {
    delete_forms_display_conditions_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_FLAT_LEADS = gql`
  query GetFlatLeads($id: uuid!) {
    forms_forms_by_pk(id: $id) {
      name
      flat_leads {
        created_at
        lead_id
        answers
      }
    }
  }
`;

export const GET_LEADS = gql`
  query GetLeads($form_id: uuid!) {
    forms_forms_by_pk(id: $form_id) {
      name
      leads(limit: 10, order_by: { created_at: desc }) {
        id
        flat_lead {
          answers
        }
        answers {
          id
          block_id
          lead_id
          value_v2
        }
      }
    }
  }
`;

export const GET_LEAD = gql`
  query GetLead($id: uuid!) {
    forms_leads_by_pk(id: $id) {
      id
      cookies
      landing_page_url
      referer_url
      user_agent
      flat_lead {
        answers
      }
    }
  }
`;

export const GET_FORM_QUESTIONS = gql`
  query GetFormQuestions($id: uuid!) {
    forms_blocks(
      where: {
        answers: { id: { _is_null: false } }
        step: { form_id: { _eq: $id } }
      }
      order_by: { step: { order: asc_nulls_last }, order: asc_nulls_last }
    ) {
      id
      block_type_id
      label
      props
    }
  }
`;

export const INSERT_FOLDER = gql`
  mutation InsertFolder($object: iam_folders_insert_input!) {
    insert_iam_folders_one(object: $object) {
      id
    }
  }
`;

export const GET_FOLDERS = gql`
  query GetFolders {
    iam_folders {
      id
      name
      created_by
    }
  }
`;

export const DELETE_FOLDER = gql`
  mutation DeleteFolder($id: uuid!) {
    delete_iam_folders_by_pk(id: $id) {
      id
    }
  }
`;

export const RENAME_FOLDER = gql`
  mutation RenameFolder($id: uuid!, $name: String!) {
    update_iam_folders_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
    }
  }
`;

export const GET_FOLDER_USERS = gql`
  query GetFolderUsers($id: uuid!) {
    iam_folders_by_pk(id: $id) {
      id
      folders_users {
        created_at
        user_id
      }
    }
  }
`;

export const INSERT_FOLDER_USER = gql`
  mutation InsertFolderUser($object: iam_folders_users_insert_input!) {
    insert_iam_folders_users_one(object: $object) {
      user_id
    }
  }
`;

export const DELETE_FOLDER_USER = gql`
  mutation DeleteFolderUser($folder_id: uuid!, $user_id: String!) {
    delete_iam_folders_users_by_pk(folder_id: $folder_id, user_id: $user_id) {
      user_id
    }
  }
`;
