import { useMutation } from "@apollo/client";
import { EditOutlined } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { RENAME_FOLDER } from "../../graphql";

type RenameFolderDialogProps = {
  folderId: string;
  folderName: string;
  onClose: () => void;
};

export const RenameFolderDialog: React.FC<RenameFolderDialogProps> = ({
  folderId,
  folderName,
  onClose,
}) => {
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [mutationError, setMutationError] = React.useState(null);
  const [renameFolder] = useMutation(RENAME_FOLDER);
  const { handleSubmit, reset, register } = useForm({
    defaultValues: {
      name: folderName,
    },
  });

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
    setTimeout(() => {
      onClose();
      reset();
    }, 300);
  };

  const onSubmit = handleSubmit((data) => {
    console.log("Submitting this form");
    setMutationError(null);
    console.log(data);
    console.log(folderId);
    setLoading(true);

    renameFolder({
      variables: {
        id: folderId,
        name: data.name,
      },
      refetchQueries: ["GetForms"],
    })
      .catch((e) => {
        setMutationError(e.message);
        setLoading(false);
      })
      .then(handleClose);
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={onSubmit} autoComplete="off">
        <DialogTitle
          id="form-dialog-title"
          style={{ display: "flex", alignItems: "center", gap: "7px" }}
        >
          <EditOutlined />
          Rename folder
        </DialogTitle>
        <DialogContent>
          {mutationError && (
            <DialogContentText color="error">{mutationError}</DialogContentText>
          )}
          <DialogContentText>
            Please enter a new name for the folder
          </DialogContentText>
          <TextField
            autoFocus
            {...register("name", { required: true })}
            margin="dense"
            id="name"
            label="Folder name"
            type="text"
            fullWidth
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            disabled={loading}
          >
            Rename
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
