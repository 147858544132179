import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { formatDistance } from "date-fns";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableCellProps,
  ButtonGroup,
  MenuItem,
} from "@mui/material";
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  NoteAddOutlined,
  CreateNewFolderOutlined,
  ArrowDropDown,
} from "@mui/icons-material";

import NavBar from "../../Components/NavBar";
import Loader from "../../Components/Loader";
import { GET_FORMS } from "../../graphql";
import { GetFormsQuery } from "../../generated/graphql";
import { CreateFolderDialog } from "./CreateFolderDialog";
import { FolderTitle } from "./FolderTitle";
import { FormMenu } from "./FormMenu";
import { CreateFormDialog } from "./CreateFormDialog";
import IconMenu from "../../Components/IconMenu";

const HeaderCell = (props: TableCellProps) => (
  <TableCell
    {...props}
    sx={{
      fontWeight: "bold",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      backgroundColor: "white",
    }}
  />
);

const Forms = () => {
  const [showArchived, setShowArchived] = useState(false);
  const [popup, setPopup] =
    useState<"createFolder" | "createForm" | null>(null);
  const [menu, setMenu] = useState<[boolean, Element | null]>([false, null]);

  const navigate = useNavigate();
  const { error, loading, data } = useQuery<GetFormsQuery>(GET_FORMS);

  if (loading) return <Loader />;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  const folders = [
    { id: null, name: "My funnels", created_by: "", created_at: "" },
    ...(data?.iam_folders || []),
  ];

  const forms = data?.forms_forms
    .filter((form) => form.is_archived === showArchived)
    .sort(
      (f1, f2) =>
        new Date(
          f2.leads_aggregate?.aggregate?.max?.created_at || 0
        ).getTime() -
        new Date(f1.leads_aggregate?.aggregate?.max?.created_at || 0).getTime()
    )
    .map((form) => ({
      ...form,
      firstStepId: form.steps[0]?.id || "",
      createdAt: formatDistance(new Date(form.created_at), Date.now(), {
        addSuffix: true,
      }),
      leadsCount: form.leads_aggregate.aggregate?.count
        ? form.leads_aggregate.aggregate?.count
        : "-",
      lastLead: form.leads_aggregate?.aggregate?.max?.created_at
        ? formatDistance(
            new Date(form.leads_aggregate?.aggregate?.max?.created_at),
            Date.now(),
            {
              addSuffix: true,
            }
          )
        : "-",
      isArchived: JSON.stringify(form.is_archived),
    }));

  interface Column {
    id: "name" | "createdAt" | "leadsCount" | "lastLead" | "isArchived";
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
  }

  const columns: Column[] = [
    {
      id: "name",
      label: "Name",
      minWidth: 170,
    },
    {
      id: "createdAt",
      label: "Created At",
      minWidth: 170,
    },
    {
      id: "leadsCount",
      label: "Leads",
      minWidth: 170,
    },
    {
      id: "lastLead",
      label: "Last lead",
      minWidth: 170,
    },
    {
      id: "isArchived",
      label: "Archived",
      minWidth: 170,
    },
  ];

  return (
    <>
      <NavBar>
        <Box sx={{ fontWeight: 600, paddingLeft: "12px" }}>Home</Box>
      </NavBar>
      {popup === "createFolder" && (
        <CreateFolderDialog onClose={() => setPopup(null)} />
      )}
      {popup === "createForm" && (
        <CreateFormDialog onClose={() => setPopup(null)} />
      )}
      <Container maxWidth="xl" sx={{ mt: 2, pb: 8 }}>
        <Grid justifyContent="flex-end" container>
          <ButtonGroup
            variant="contained"
            size="large"
            color="secondary"
            disableElevation
          >
            <Button
              startIcon={<NoteAddOutlined />}
              onClick={() => setPopup("createForm")}
            >
              Create Funnel
            </Button>
            <Button
              size="small"
              onClick={(e) => setMenu([true, e.currentTarget])}
            >
              <ArrowDropDown />
            </Button>
          </ButtonGroup>
          <IconMenu
            open={menu[0]}
            onClose={() => setMenu([false, null])}
            anchorEl={menu[1]}
          >
            <MenuItem
              onClick={() => {
                setPopup("createFolder");
                setMenu([false, null]);
              }}
            >
              <CreateNewFolderOutlined />
              Create Folder
            </MenuItem>
            {showArchived ? (
              <MenuItem
                onClick={() => {
                  setShowArchived(false);
                  setMenu([false, null]);
                }}
              >
                <VisibilityOffOutlined />
                Hide archived
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  setShowArchived(true);
                  setMenu([false, null]);
                }}
              >
                <VisibilityOutlined />
                Show archived
              </MenuItem>
            )}
          </IconMenu>
        </Grid>
        {folders.map((folder) => {
          const subForms = forms?.filter(
            (form) => form.folder_id === folder.id
          );
          return (
            <Grid
              container
              spacing={2}
              marginTop={1}
              marginBottom={1}
              key={folder.id}
            >
              <Grid item xs={12}>
                <FolderTitle folder={folder} />
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderColor: "divider",
                    borderWidth: 1,
                    borderRadius: 2,
                    borderStyle: "solid",
                    borderBottom: 0,
                  }}
                >
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <HeaderCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </HeaderCell>
                          ))}
                          <HeaderCell key="actions" align="right">
                            Actions
                          </HeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subForms?.map((r) => {
                          return (
                            <TableRow
                              hover={!showArchived}
                              role="checkbox"
                              tabIndex={-1}
                              key={r.id}
                            >
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  sx={{
                                    cursor: showArchived
                                      ? "default"
                                      : "pointer",
                                  }}
                                  onClick={() =>
                                    showArchived
                                      ? null
                                      : navigate(
                                          `/forms/${r.id}/editor/${r.firstStepId}`
                                        )
                                  }
                                >
                                  {r[column.id]}
                                </TableCell>
                              ))}
                              <TableCell key="actions" align="right">
                                <FormMenu
                                  id={r.id}
                                  name={r.name}
                                  showArchived={showArchived}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          );
        })}
      </Container>
    </>
  );
};

export default Forms;
