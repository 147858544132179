import React from "react";
import type {} from "@mui/lab/themeAugmentation";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 550,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    unit: "px",
  },
  palette: {
    primary: {
      main: "#1565c0",
    },
    secondary: {
      main: "#05B169",
      contrastText: "#ffffff",
    },
    background: {
      default: "#fafafa",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "system-ui",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      '"Fira Sans"',
      '"Droid Sans"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: "#000000",
          backgroundColor: "#ffffff",
          borderBottom: "1px solid rgba(0, 0, 0, .12)",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        ol: {
          li: {
            a: {
              color: "#1565c0",
              padding: "10px 12px",
              marginRight: "-8px",
              display: "block",
              borderRadius: "7px",
              textDecoration: "none",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, .04)",
              },
            },
            "&:last-child": {
              color: "#000000",
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // Need to style through root because of zIndex
          "&:hover": {
            ".MuiTouchRipple-root": {
              backgroundColor: "rgba(0, 0, 0, .04)",
            },
          },
          // Change min height of button to 64px
          minHeight: "64px",
          ".MuiSvgIcon-root": {
            fontSize: "18px",
          },
          ".MuiTouchRipple-root": {
            borderRadius: "7px",
            margin: "10px 1px",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: "7px",
          margin: "1px 7px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#212529",
          fontFamily: [
            "-apple-system",
            "system-ui",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            '"Fira Sans"',
            '"Droid Sans"',
            "Arial",
            "sans-serif",
          ].join(","),
        },
        h1: {
          fontSize: "26px",
        },
        h2: {
          fontSize: "18px",
        },
        h3: {
          fontSize: "18px",
          fontWeight: "bold",
        },
        h4: {
          fontSize: "16px",
        },
      },
    },
  },
});

const Theme: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
