import { AppBar, Box, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../logo.png";
import { LogOutButton } from "../Providers/Auth";

const NavBar: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <AppBar position="fixed" color="primary" elevation={0}>
      <Toolbar>
        <Link to={`/forms`} style={{ display: "flex", marginRight: 12 }}>
          <img src={logo} style={{ width: 40 }} alt="logo" />
        </Link>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          {children}
        </Box>
        <LogOutButton />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
