import React, { useState, useEffect, useMemo } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  TextField,
  FormControlLabel,
  FormHelperText,
  FormGroup,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  MkAddressArgTypes,
  MkAddressFullArgTypes,
  MkButtonArgTypes,
  MkFetchArgTypes,
  MkTextFieldArgTypes,
  MkRadioGroupArgTypes,
  MkCheckboxArgTypes,
  MkPre,
  MkPhoneInputArgTypes,
  MkHtmlArgTypes,
} from "lib";

import { BLOCK_FRAGMENT, UPDATE_BLOCK } from "../graphql";

import {
  GetFormQuery,
  UpdateBlockMutation,
  UpdateBlockMutationVariables,
} from "../generated/graphql";

import FormatButtons from "./FormatButtons";

type FormType = NonNullable<GetFormQuery["forms_forms_by_pk"]>;

type Block = NonNullable<
  GetFormQuery["forms_forms_by_pk"]
>["steps"][0]["blocks"][0];

const BlockSettings: React.FC<{ form: FormType }> = ({ form }) => {
  const { blockId } =
    useParams<{
      formId: string;
      blockId: string;
    }>();

  const client = useApolloClient();

  const block = client.readFragment({
    id: `forms_blocks:${blockId}`,
    fragment: BLOCK_FRAGMENT,
    fragmentName: "BlockFragment",
  }) as Block;

  console.log({ block });

  /*
  const block = form.steps
    .flatMap((step) => step.blocks)
    .find((block) => block.id === blockId);
  */

  const defaultValues = useMemo(
    () => ({
      id: block?.id,
      label: block?.label,
      props: block?.props,
    }),
    [block]
  );

  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<Block>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [updateBlock] =
    useMutation<UpdateBlockMutation, UpdateBlockMutationVariables>(
      UPDATE_BLOCK
    );

  const [mutationError, setMutationError] = useState(null);

  // const refetchQueries = [{ query: GET_FORM, variables: { id: formId } }];

  const onSubmit = handleSubmit((formData) => {
    if (!isDirty) return;
    setMutationError(null);

    const { id, ..._set } = formData;

    const optimisticResponse = {
      update_forms_blocks_by_pk: {
        __typename: "forms_blocks",
        ...block,
        props: {
          ...block?.props,
          ..._set.props,
        },
      } as any,
    };
    console.log({ optimisticResponse });

    updateBlock({
      variables: { id, _set },
      optimisticResponse,
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({
            __typename: "forms_blocks",
            id: data?.update_forms_blocks_by_pk?.id,
          }),
          fields: {
            props: () => data?.update_forms_blocks_by_pk?.props,
          },
        });
      },
      // refetchQueries,
    }).catch((e) => setMutationError(e.message));
  });

  const argTypes = useMemo(
    () =>
      ({
        "input-text": MkTextFieldArgTypes,
        fetch: MkFetchArgTypes,
        button: MkButtonArgTypes,
        "input-radio": MkRadioGroupArgTypes,
        "input-checkbox": MkCheckboxArgTypes,
        "input-phone": MkPhoneInputArgTypes,
        "input-address": MkAddressArgTypes,
        "input-address-full": MkAddressFullArgTypes,
        html: MkHtmlArgTypes,
      }[block?.block_type_id || ""]),
    [block]
  );

  // Watch for changes in the form and update the block props (debounced)
  const debounced = useDebouncedCallback((values) => onSubmit(values), 500, {
    maxWait: 1000,
    leading: false,
    trailing: true,
  });
  useEffect(() => {
    const subscription = watch(debounced);
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, debounced]);

  if (!block) return null;

  return (
    <>
      <Typography variant="h3">Edit block</Typography>
      <FormatButtons />
      <form onSubmit={onSubmit} autoComplete="off">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
          }}
        >
          {mutationError && <Alert severity="error">{mutationError}</Alert>}
          {!argTypes && (
            <>
              <TextField
                {...register("label", { required: true })}
                id="label"
                label="Label"
                size="small"
                margin="dense"
                fullWidth
              />
              <TextField
                {...register("props", { required: true })}
                id="props"
                label="Props"
                size="small"
                multiline
                minRows={3}
                margin="dense"
                fullWidth
              />
            </>
          )}
          {argTypes &&
            Object.entries(argTypes)
              .filter(([key, value]) => value.hasOwnProperty("control"))
              .map(([key, value]: any) => {
                switch (value.control) {
                  case "text":
                    return (
                      <TextField
                        {...register(`props.${key}`, { required: false })}
                        id={`${block.id}.props.${key}`}
                        key={`${block.id}.props.${key}`}
                        label={value.name || key}
                        size="small"
                        margin="dense"
                        multiline={
                          ["placeholder", "defaultValue"].includes(key) &&
                          block.props.multiline
                        }
                        minRows={
                          ["placeholder", "defaultValue"].includes(key) &&
                          block.props.minRows
                        }
                        helperText={value.description}
                        fullWidth
                      />
                    );
                  case "number":
                    return (
                      <TextField
                        {...register(`props.${key}`, { required: false })}
                        id={`props.${key}`}
                        key={`${block.id}.props.${key}`}
                        label={value.name ? value.name : key}
                        size="small"
                        margin="dense"
                        type="number"
                        inputMode="numeric"
                        inputProps={{ min: 1, style: { textAlign: "center" } }}
                        fullWidth
                        helperText={value.description}
                      />
                    );
                  case "boolean":
                    return (
                      <Controller
                        name={`props.${key}`}
                        key={`${block.id}.props.${key}`}
                        control={control}
                        render={({ field }) => (
                          <FormGroup sx={{ width: 1, px: 1 }}>
                            <FormControlLabel
                              sx={{ width: 1 }}
                              label={value.name ? value.name : key}
                              control={
                                <Switch
                                  onChange={(e) =>
                                    field.onChange(e.target.checked)
                                  }
                                  checked={field.value}
                                  size="small"
                                />
                              }
                            />
                            {value.description && (
                              <FormHelperText>
                                {value.description}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        )}
                      />
                    );
                  case "object":
                    return (
                      <Controller
                        name={`props.${key}`}
                        key={`${block.id}.props.${key}`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            label={value.name ? value.name : key}
                            multiline
                            minRows={3}
                            fullWidth
                            onBlur={(e) => {
                              try {
                                field.onChange(JSON.parse(e.target.value));
                              } catch (err) {}
                            }}
                            size="small"
                            defaultValue={JSON.stringify(field.value, null, 2)}
                            inputProps={{
                              style: {
                                whiteSpace: "pre",
                                overflowX: "scroll",
                                wordWrap: "break-word",
                                fontSize: "14px",
                                fontWeight: 500,
                                fontFamily: `ui-monospace, Menlo, Monaco, 
                                    "Cascadia Mono", "Segoe UI Mono", 
                                    "Roboto Mono", 
                                    "Oxygen Mono", 
                                    "Ubuntu Monospace", 
                                    "Source Code Pro",
                                    "Fira Mono", 
                                    "Droid Sans Mono", 
                                    "Courier New", monospace`,
                              },
                            }}
                            helperText={value.description}
                          />
                        )}
                      />
                    );
                  case "code":
                    return (
                      <TextField
                        {...register(`props.${key}`, { required: false })}
                        id={`${block.id}.props.${key}`}
                        key={`${block.id}.props.${key}`}
                        label={value.name || key}
                        size="small"
                        margin="dense"
                        multiline
                        minRows={5}
                        helperText={value.description}
                        fullWidth
                        inputProps={{
                          style: {
                            whiteSpace: "pre",
                            overflowX: "scroll",
                            wordWrap: "break-word",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: `ui-monospace, Menlo, Monaco, 
                                "Cascadia Mono", "Segoe UI Mono", 
                                "Roboto Mono", 
                                "Oxygen Mono", 
                                "Ubuntu Monospace", 
                                "Source Code Pro",
                                "Fira Mono", 
                                "Droid Sans Mono", 
                                "Courier New", monospace`,
                          },
                        }}
                      />
                    );
                  case "select":
                    return (
                      <Controller
                        name={`props.${key}`}
                        key={`${block.id}.props.${key}`}
                        control={control}
                        render={({ field }) => (
                          <FormControl fullWidth size="small">
                            <InputLabel id={`props.${key}`}>
                              {value.name ? value.name : key}
                            </InputLabel>
                            <Select
                              labelId={`props.${key}`}
                              {...field}
                              fullWidth
                              size="small"
                              label={value.name ? value.name : key}
                            >
                              {value.options.map((option: string) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                            {value.description && (
                              <FormHelperText>
                                {value.description}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    );
                  default:
                    return null;
                }
              })}
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            disableElevation
            color="primary"
          >
            Save
          </Button>
          <Button fullWidth color="primary">
            Cancel
          </Button>
        </Box>
      </form>
      {process.env.NODE_ENV === "development" && (
        <MkPre>{JSON.stringify(block, null, 2)}</MkPre>
      )}
    </>
  );
};

export default BlockSettings;
