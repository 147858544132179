// This component renders a MUI dialog that asks the user to confirm the deletion of a form.

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@apollo/client";
import { DELETE_FORM } from "../../graphql";
import { DeleteOutlineOutlined } from "@mui/icons-material";

type DeleteFormDialogProps = {
  formId: string;
  formName: string;
  onClose: () => void;
};

export const DeleteFormDialog: React.FC<DeleteFormDialogProps> = ({
  formId,
  formName,
  onClose,
}) => {
  const [open, setOpen] = React.useState(true);

  const [deleteForm] = useMutation(DELETE_FORM);
  const [mutationError, setMutationError] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const onConfirm = () => {
    setMutationError(null);

    deleteForm({
      variables: { id: formId },
      optimisticResponse: {
        delete_forms_forms_by_pk: {
          __typename: "forms_forms",
          id: formId,
        },
      },
      update(cache, { data: { delete_forms_forms_by_pk } }) {
        console.log(delete_forms_forms_by_pk);
        cache.evict({ id: `forms_forms:${delete_forms_forms_by_pk.id}` });
        cache.gc();
      },
      refetchQueries: ["GetForms"],
    })
      .catch((e) => setMutationError(e.message))
      .then(() => {
        handleClose();
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", alignItems: "center", gap: "7px" }}
        >
          <DeleteOutlineOutlined sx={{ color: "error.main" }} />
          Delete Form
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the form "{formName}"?
            <br />
            This action cannot be undone, you will lose all the leads collected
            in this form.
          </DialogContentText>
          {mutationError && (
            <DialogContentText color="error">
              {JSON.stringify(mutationError)}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={onConfirm}
            autoFocus
            variant="contained"
            color="error"
            disableElevation
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
