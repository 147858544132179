import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { CreateNewFolderOutlined } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { INSERT_FOLDER } from "../../graphql";
import { Alert } from "@mui/material";

type CreateFolderDialogProps = {
  onClose: () => void;
};

export const CreateFolderDialog: React.FC<CreateFolderDialogProps> = ({
  onClose,
}) => {
  const { user } = useAuth0();
  const [open, setOpen] = React.useState(true);
  const { handleSubmit, reset, register } = useForm();
  const [createFolder] = useMutation(INSERT_FOLDER);
  const [mutationError, setMutationError] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
    setMutationError(null);
    reset();
    setTimeout(() => onClose(), 300);
  };

  const onSubmit = handleSubmit((data) => {
    console.log("Submitting this form");
    setMutationError(null);
    console.log(data);
    console.log(user);

    const isAdmin =
      user?.["https://hasura.io/jwt/claims"]?.["x-hasura-role"] === "admin";
    console.log({ isAdmin });
    createFolder({
      variables: {
        object: {
          name: data.name,
          created_by: isAdmin ? user?.email : undefined,
          folders_users: {
            data: [
              {
                user_id: user?.email,
              },
            ],
          },
        },
      },
      refetchQueries: ["GetForms"],
    })
      .then(handleClose)
      .catch((e) => setMutationError(e.message));
  });

  React.useMemo(() => setOpen(true), []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={onSubmit} autoComplete="off">
        <DialogTitle
          style={{ display: "flex", alignItems: "center", gap: "7px" }}
        >
          <CreateNewFolderOutlined />
          Create new folder
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Folders allow you to organize your funnels and collaborate with
            other people by sharing access with them.
          </DialogContentText>
          {mutationError && (
            <Alert severity="error">
              {JSON.stringify(mutationError, null, 2)}
            </Alert>
          )}
          <TextField
            {...register("name", { required: true })}
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disableElevation
          >
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
