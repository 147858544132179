import React, { useState, useEffect, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  Container,
  Paper,
  Typography,
  TextField,
} from "@mui/material";

import { FolderPicker } from "../../Components/FolderPicker";

import { UPDATE_FORM, FORM_FRAGMENT } from "../../graphql";

import {
  GetFormQuery,
  UpdateFormMutation,
  UpdateFormMutationVariables,
} from "../../generated/graphql";

type Form = NonNullable<GetFormQuery["forms_forms_by_pk"]>;

const FormSettings: React.FC<{ form: Form }> = ({ form }) => {
  const defaultValues = useMemo(
    () => ({
      id: form?.id,
      name: form?.name,
      slug: form?.slug,
      gtm_id: form?.gtm_id,
      folder_id: form?.folder_id,
      head_code: form?.head_code,
      body_code: form?.body_code,
    }),
    [form]
  );

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isDirty },
  } = useForm<Form>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [updateForm] =
    useMutation<UpdateFormMutation, UpdateFormMutationVariables>(UPDATE_FORM);

  const [mutationError, setMutationError] = useState(null);

  const onSubmit = handleSubmit((data) => {
    setMutationError(null);
    const { id, ..._set } = data;
    console.log(data);
    updateForm({
      variables: { id, _set },
      optimisticResponse: {
        update_forms_forms_by_pk: {
          ...form,
          ...data,
          updated_at: new Date().toISOString(),
        },
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: `forms_forms:${data?.update_forms_forms_by_pk?.id}`,
          fragment: FORM_FRAGMENT,
          data: data?.update_forms_forms_by_pk,
        });
      },
    }).catch((e) => setMutationError(e.message));
  });

  return (
    <Container maxWidth="sm">
      <Paper sx={{ padding: 2, margin: 2 }}>
        <Typography variant="h3" m={1}>
          Form Settings
        </Typography>
        <form onSubmit={onSubmit} autoComplete="off">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              gap: "12px",
            }}
          >
            {mutationError && <Alert severity="error">{mutationError}</Alert>}
            <TextField
              {...register("name", { required: true })}
              id="name"
              label="Name"
              size="small"
              margin="dense"
              fullWidth
            />
            <Controller
              name="folder_id"
              control={control}
              render={({ field }) => <FolderPicker field={field} />}
            />
            <TextField
              {...register("slug", { required: true })}
              id="slug"
              label="Slug"
              size="small"
              margin="dense"
              fullWidth
            />
            <TextField
              {...register("gtm_id", { required: false })}
              id="gtm_id"
              label="GTM Container ID"
              placeholder="GTM-XXXXXXX"
              size="small"
              margin="dense"
              fullWidth
            />
            <TextField
              {...register("head_code", { required: false })}
              inputProps={{
                style: {
                  whiteSpace: "pre",
                  overflowX: "auto",
                  wordWrap: "break-word",
                  fontSize: "14px",
                  fontWeight: 500,
                  fontFamily: `ui-monospace, Menlo, Monaco, 
                      "Cascadia Mono", "Segoe UI Mono", 
                      "Roboto Mono", 
                      "Oxygen Mono", 
                      "Ubuntu Monospace", 
                      "Source Code Pro",
                      "Fira Mono", 
                      "Droid Sans Mono", 
                      "Courier New", monospace`,
                },
              }}
              id="head_code"
              label="Head code"
              size="small"
              margin="dense"
              multiline
              minRows={4}
              fullWidth
            />
            <TextField
              {...register("body_code", { required: false })}
              inputProps={{
                style: {
                  whiteSpace: "pre",
                  overflowX: "auto",
                  wordWrap: "break-word",
                  fontSize: "14px",
                  fontWeight: 500,
                  fontFamily: `ui-monospace, Menlo, Monaco, 
                      "Cascadia Mono", "Segoe UI Mono", 
                      "Roboto Mono", 
                      "Oxygen Mono", 
                      "Ubuntu Monospace", 
                      "Source Code Pro",
                      "Fira Mono", 
                      "Droid Sans Mono", 
                      "Courier New", monospace`,
                },
              }}
              id="body_code"
              label="Body code"
              size="small"
              margin="dense"
              multiline
              minRows={4}
              fullWidth
            />
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 1, m: 2 }}
          >
            <Button color="primary" onClick={() => reset()} disabled={!isDirty}>
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              disableElevation
              color="primary"
              disabled={!isDirty}
            >
              Save
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default FormSettings;
