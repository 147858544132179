import React, { useState, useEffect, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";

import { GET_FORM, UPDATE_STEP } from "../graphql";

import {
  GetFormQuery,
  UpdateStepMutation,
  UpdateStepMutationVariables,
} from "../generated/graphql";

import DisplayConditions from "./DisplayConditions";

type FormType = NonNullable<GetFormQuery["forms_forms_by_pk"]>;
type Step = NonNullable<GetFormQuery["forms_forms_by_pk"]>["steps"][0];

const StepSettings: React.FC<{ form: FormType }> = ({ form }) => {
  const { stepId } = useParams<{ stepId: string }>();

  const step = useMemo(
    () => form.steps.find((step) => step.id === stepId),
    [form, stepId]
  );

  const defaultValues = useMemo(
    () => ({
      id: step?.id,
      name: step?.name,
      slug: step?.slug,
      webhook_send: step?.webhook_send,
      webhook_url: step?.webhook_url,
    }),
    [step]
  );

  const { control, handleSubmit, register, reset, watch } = useForm<Step>({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [updateStep] =
    useMutation<UpdateStepMutation, UpdateStepMutationVariables>(UPDATE_STEP);

  const [mutationError, setMutationError] = useState(null);

  const refetchQueries = [{ query: GET_FORM, variables: { id: form.id } }];

  const onSubmit = handleSubmit((data) => {
    setMutationError(null);
    const { id, ..._set } = data;
    updateStep({ variables: { id, _set }, refetchQueries }).catch((e) =>
      setMutationError(e.message)
    );
  });

  const webhook_send = watch("webhook_send", step?.webhook_send);

  return (
    <>
      <Typography variant="h3" mb={2}>
        Edit step
      </Typography>
      <form onSubmit={onSubmit} autoComplete="off">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
          }}
        >
          {mutationError && <Alert severity="error">{mutationError}</Alert>}
          <TextField
            {...register("name", { required: true })}
            required
            id="name"
            label="Name"
            size="small"
            margin="dense"
            helperText="The name of this step."
            fullWidth
          />
          <TextField
            {...register("slug", { required: true })}
            id="slug"
            label="Slug"
            size="small"
            margin="dense"
            helperText="The part of the form's URL that identifies the step."
            required
            fullWidth
          />
          <Controller
            name="webhook_send"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                sx={{ width: "100%", ml: 1 }}
                control={
                  <Switch
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                }
                label="Webhook on submit"
              />
            )}
          />
          {webhook_send && (
            <TextField
              {...register("webhook_url")}
              id="webhook_url"
              label="Webhook URL"
              size="small"
              margin="dense"
              helperText="The URL to send the form data."
              fullWidth
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            disableElevation
            color="primary"
          >
            Save
          </Button>
          <Button fullWidth color="primary" onClick={() => reset()}>
            Cancel
          </Button>
        </Box>
      </form>
      <DisplayConditions />
    </>
  );
};

export default StepSettings;
