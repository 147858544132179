import React, { useState } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Alert,
  Button,
  Select,
  FormControl,
  Box,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import AltRouteIcon from "@mui/icons-material/AltRoute";

import {
  GET_FORM,
  GET_BLOCKS_VALUES,
  INSERT_DISPLAY_CONDITION,
  DELETE_DISPLAY_CONDITION,
  STEP_FRAGMENT,
} from "../graphql";

import {
  GetFormQuery,
  GetBlocksValuesQuery,
  InsertDisplayConditionMutation,
  InsertDisplayConditionMutationVariables,
  DeleteDisplayConditionMutation,
  DeleteDisplayConditionMutationVariables,
} from "../generated/graphql";

type Step = NonNullable<GetFormQuery["forms_forms_by_pk"]>["steps"][0];

const DisplayConditions = () => {
  const { formId, stepId } = useParams<{ formId: string; stepId?: string }>();
  const client = useApolloClient();
  const step: Step | null | undefined = client.readFragment({
    id: `forms_steps:${stepId}`,
    fragment: STEP_FRAGMENT,
  });

  const { error, loading, data } = useQuery<GetBlocksValuesQuery>(
    GET_BLOCKS_VALUES,
    {
      variables: { id: formId },
    }
  );

  const [insertDisplayCondition] = useMutation<
    InsertDisplayConditionMutation,
    InsertDisplayConditionMutationVariables
  >(INSERT_DISPLAY_CONDITION);

  const [deleteDisplayCondition] = useMutation<
    DeleteDisplayConditionMutation,
    DeleteDisplayConditionMutationVariables
  >(DELETE_DISPLAY_CONDITION);

  const refetchQueries = [{ query: GET_FORM, variables: { id: formId } }];

  const [showForm, setShowForm] = useState<boolean>(false);
  const [blockId, setBlockId] = useState<string>("");
  const [answerValue, setAnswerValue] = useState<string>("");
  const [operator, setOperator] = useState<string>("");

  const { handleSubmit } = useForm<any>();

  if (loading) return <div>Loading...</div>;
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

  let blocks = [] as {
    id: string;
    label?: string;
    answers: any;
    options: any;
  }[];
  data?.forms_forms_by_pk?.steps.map((step) => {
    step.blocks.map((block) =>
      blocks.push({
        id: block.id,
        label: block?.label?.replace(/<\/?[^>]+(>|$)/g, ""),
        answers: block.answers,
        options: block.options,
      })
    );
    return null;
  });

  const onSubmit = handleSubmit(() => {
    // setMutationError(null);
    insertDisplayCondition({
      variables: {
        step_id: stepId,
        block_id: blockId,
        operator,
        values: [answerValue],
      },
      refetchQueries,
    }).then((r) => destroyForm());
  });

  const destroyForm = () => {
    setAnswerValue("");
    setOperator("");
    setBlockId("");
    setShowForm(false);
  };

  return (
    <>
      <Typography variant="caption" display="block" my={1}>
        Display conditions
      </Typography>
      {step?.display_conditions.map((condition) => (
        <Alert
          sx={{ mt: 1 }}
          icon={<AltRouteIcon />}
          severity="info"
          onClose={() =>
            deleteDisplayCondition({
              variables: { id: condition.id },
              refetchQueries,
            })
          }
        >
          <strong>
            {condition.block.label?.replace(/<\/?[^>]+(>|$)/g, "")}
          </strong>
          <div>{condition.operator}</div>
          <div>{condition.values.join(", ")}</div>
        </Alert>
      ))}
      {step?.display_conditions.length === 0 && !showForm && (
        <Typography
          variant="caption"
          display="block"
          my={1}
          style={{ fontStyle: "italic" }}
        >
          No conditions
        </Typography>
      )}
      {showForm ? (
        <form onSubmit={onSubmit} autoComplete="off">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <FormControl size="small" fullWidth>
              <InputLabel id="question-select-label">Question</InputLabel>
              <Select
                labelId="question-select-label"
                id="question-select"
                label="Question"
                onChange={(e) => {
                  setBlockId(e.target.value as string);
                  setAnswerValue("");
                }}
                value={blockId}
              >
                {blocks.map((block: any) => (
                  <MenuItem value={block.id} key={block.id}>
                    {block.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" fullWidth>
              <InputLabel id="operator-select-label">Operator</InputLabel>
              <Select
                labelId="operator-select-label"
                id="operator-select"
                label="Operator"
                onChange={(e) => {
                  setOperator(e.target.value as string);
                }}
                value={operator}
              >
                {[
                  { id: "_eq", label: "is equal" },
                  { id: "_neq", label: "is not equal" },
                  { id: "_in", label: "is in" },
                  { id: "_nin", label: "is not in" },
                ].map((op: any) => (
                  <MenuItem value={op.id} key={op.id}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="answer-select-label">Answer</InputLabel>
              <Select
                labelId="answer-select-label"
                id="answer-select"
                label="Answer"
                onChange={(e) => setAnswerValue(e.target.value as string)}
                value={answerValue}
              >
                {blockId &&
                  blocks
                    .filter((b) => b.id === blockId)[0]
                    .options.map((option: any) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              disableElevation
              color="primary"
            >
              Save
            </Button>
            <Button fullWidth color="primary" onClick={destroyForm}>
              Cancel
            </Button>
          </Box>
        </form>
      ) : (
        <Button fullWidth onClick={() => setShowForm(true)}>
          Add condition
        </Button>
      )}
    </>
  );
};

export default DisplayConditions;
