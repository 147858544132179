import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Toolbar,
} from "@mui/material";
import { Theme } from "lib";
import { LinearProgress } from "@mui/material";

import BlocksDock from "../../Components/BlocksDock";
import logo from "../../logo.svg";
import { GetFormQuery } from "../../generated/graphql";
import Drawer from "../../Components/Drawer";
import Step from "../../Components/Step";

type Form = NonNullable<GetFormQuery["forms_forms_by_pk"]>;

const Editor: React.FC<{ form: Form }> = ({ form }) => {
  const { stepId, blockId } =
    useParams<{ formId: string; stepId?: string; blockId?: string }>();
  const navigate = useNavigate();
  const step = form?.steps.filter((s) => s.id === stepId)[0];
  const progress =
    (((step?.order || 0) + 1) / (form?.steps?.length || 1)) * 100 - 5;

  const [lead, setLead] = useState<any>(form.leads[0]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Drawer form={form} />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default" }}
          onClick={() =>
            false &&
            navigate(`/forms/${form.id}/editor/${stepId || form.steps[0].id}`)
          }
        >
          <AppBar position="static" elevation={0}>
            <Toolbar variant="dense">
              <BlocksDock form={form} />
              <FormControl size="small" sx={{ m: 1, width: 150 }}>
                <Select
                  displayEmpty
                  renderValue={(v: any) => {
                    return v ? v.id : <em>Select lead</em>;
                  }}
                  value={lead}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setLead(e.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>Select lead</em>
                  </MenuItem>
                  {(form?.leads || []).map((lead) => (
                    <MenuItem key={lead.id} value={lead as any}>
                      {lead.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm">
            <Paper
              sx={{
                p: "20px",
                m: 4,
                borderRadius: 3,
                boxShadow: "0 15px 22px 2px rgb(0 0 0 / 10%)",
              }}
              elevation={0}
            >
              <Theme>
                <Box
                  id="form-header"
                  sx={{
                    m: "0 -20px",
                    p: "0 20px",
                    border: blockId === "header" ? "1px solid red" : "none",
                  }}
                  onClick={(e) => {
                    navigate(`/forms/${form.id}/editor/${stepId}/header`);
                  }}
                >
                  <img
                    src={form?.header_image_url || logo}
                    alt="logo"
                    style={{
                      margin: "20px auto",
                      maxWidth: form.header_image_max_width || "400px",
                      maxHeight: form.header_image_max_height || "40px",
                      display: "block",
                    }}
                  />
                  {form.show_progress_bar && (
                    <LinearProgress variant="determinate" value={progress} />
                  )}
                </Box>
                <Step form={form} lead={lead} />
              </Theme>
            </Paper>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Editor;
