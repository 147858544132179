import { useMutation, useApolloClient } from "@apollo/client";
import {
  MoreHorizOutlined,
  UnarchiveOutlined,
  DeleteOutlineOutlined,
  GroupOutlined,
  EditOutlined,
  FileCopyOutlined,
  ArchiveOutlined,
  MoreHoriz,
} from "@mui/icons-material";
import { IconButton, MenuItem, Divider } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

import IconMenu from "../../Components/IconMenu";
import { FormFragmentFragment } from "../../generated/graphql";
import { UPDATE_FORM, FORM_FRAGMENT } from "../../graphql";
import { DeleteFormDialog } from "./DeleteFormDialog";
import { DuplicateFormDialog } from "./DuplicateFormDialog";

type FormMenuProps = {
  id: string;
  name: string;
  showArchived: boolean;
};

export const FormMenu: React.FC<FormMenuProps> = ({
  id,
  name,
  showArchived,
}) => {
  const [menu, setMenu] = useState<[boolean, Element | null]>([false, null]);
  const [dialog, setDialog] =
    useState<"duplicateForm" | "deleteForm" | null>(null);

  const [updateForm] = useMutation(UPDATE_FORM);

  const client = useApolloClient();

  const handleDelete = () => {
    setDialog("deleteForm");
    setMenu([false, null]);
  };

  const handleDuplicate = () => {
    setDialog("duplicateForm");
    setMenu([false, null]);
  };

  const handleArchive = async (id: string) => {
    const form = client.readFragment<FormFragmentFragment>({
      id: `forms_forms:${id}`,
      fragment: FORM_FRAGMENT,
    });
    updateForm({
      variables: { id, _set: { is_archived: !showArchived } },
      optimisticResponse: {
        update_forms_forms_by_pk: {
          ...form,
          updated_at: new Date().toISOString(),
          is_archived: !showArchived,
        },
      },
      update: (cache, { data }) => {
        cache.writeFragment({
          id: `forms_forms:${id}`,
          fragment: FORM_FRAGMENT,
          data: data.update_forms_forms_by_pk,
        });
      },
    });

    setMenu([false, null]);
  };

  return (
    <>
      {dialog === "deleteForm" && (
        <DeleteFormDialog
          formId={id}
          formName={name}
          onClose={() => setDialog(null)}
        />
      )}
      {dialog === "duplicateForm" && (
        <DuplicateFormDialog
          formId={id}
          formName={name}
          onClose={() => setDialog(null)}
        />
      )}
      <IconButton onClick={(e) => setMenu([true, e.currentTarget])}>
        <MoreHorizOutlined />
      </IconButton>
      <IconMenu
        id="basic-menu"
        open={menu[0]}
        onClose={() => setMenu([false, null])}
        anchorEl={menu[1]}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {showArchived
          ? [
              <MenuItem onClick={() => handleArchive(id)} key="restore">
                <UnarchiveOutlined />
                Restore
              </MenuItem>,
              <MenuItem onClick={() => handleDelete()} key="delete">
                <DeleteOutlineOutlined />
                Delete
              </MenuItem>,
            ]
          : [
              <MenuItem component={Link} to={`/forms/${id}/leads`} key="leads">
                <GroupOutlined />
                Leads
              </MenuItem>,
              <Divider sx={{ my: 0.5 }} key="divider1" />,
              <MenuItem component={Link} to={`/forms/${id}/editor`} key="edit">
                <EditOutlined />
                Edit
              </MenuItem>,
              <MenuItem onClick={handleDuplicate} key="duplicate">
                <FileCopyOutlined />
                Duplicate
              </MenuItem>,
              <Divider sx={{ my: 0.5 }} key="divider2" />,
              <MenuItem onClick={() => handleArchive(id)} key="archive">
                <ArchiveOutlined />
                Archive
              </MenuItem>,
              <MenuItem
                component={Link}
                to={`/forms/${id}/settings`}
                key="settings"
              >
                <MoreHoriz />
                More
              </MenuItem>,
            ]}
      </IconMenu>
    </>
  );
};
