import React from "react";
import { Drawer as MaterialDrawer, Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import Steps from "../Components/Steps";
import OptionSettings from "../Components/OptionSettings";
import BlockSettings from "../Components/BlockSettings";
import StepSettings from "../Components/StepSettings";
import HeaderSettings from "./HeaderSettings";

import { GetFormQuery } from "../generated/graphql";

const drawerWidth = 600;
type Form = NonNullable<GetFormQuery["forms_forms_by_pk"]>;

const Drawer: React.FC<{ form: Form }> = ({ form }) => {
  const { stepId, blockId, optionId } =
    useParams<{
      stepId: string;
      blockId: string;
      optionId: string;
    }>();

  return (
    <MaterialDrawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          marginTop: "65px",
          marginBottom: "65px",
          paddingBottom: "65px",
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Grid container style={{ height: "100%", overflowY: "auto" }}>
        <Grid
          item
          xs={5}
          style={{
            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            paddingBottom: "65px",
          }}
        >
          <Steps form={form} />
        </Grid>
        <Grid item xs={7} style={{ height: "100%" }} p={1.5}>
          {(() => {
            if (optionId) return <OptionSettings form={form} key={optionId} />;
            if (blockId === "header")
              return <HeaderSettings form={form} key="header" />;
            if (blockId) return <BlockSettings form={form} key={blockId} />;
            if (stepId) return <StepSettings form={form} key={stepId} />;
            return <></>;
          })()}
        </Grid>
      </Grid>
    </MaterialDrawer>
  );
};

export default Drawer;
