import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  useParams,
  useLocation,
  Link,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { GET_FORM } from "../../graphql";
import { GetFormQuery } from "../../generated/graphql";
import { Box, Breadcrumbs, Tab, Tabs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import NavBar from "../../Components/NavBar";
import Loader from "../../Components/Loader";
import Leads from "./Leads";
import Editor from "./Editor";
import FormSettings from "./Settings";
import {
  EditOutlined,
  GroupOutlined,
  OpenInNew,
  SettingsOutlined,
} from "@mui/icons-material";

const FormNavBar: React.FC<{ form: any }> = ({ form }) => {
  const location = useLocation();
  const [module, setModule] = useState(location.pathname.split("/")[3] || "");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "open") return;
    setModule(newValue);
  };
  return (
    <NavBar>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        style={{
          minWidth: 250,
          maxWidth: 500,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Link to="/">Funnels</Link>
        <Typography fontWeight={600}>{form?.name || "Loading..."}</Typography>
      </Breadcrumbs>
      <Box sx={{ flexGrow: 1 }}>
        <Tabs
          value={module}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ height: 64 }}
          centered
        >
          <Tab
            label="Edit"
            value="editor"
            iconPosition="start"
            icon={<EditOutlined />}
            sx={{ height: 64 }}
            component={Link}
            to={`/forms/${form?.id}/editor`}
          />
          <Tab
            label="Leads"
            value="leads"
            iconPosition="start"
            icon={<GroupOutlined />}
            sx={{ height: 64 }}
            component={Link}
            to={`/forms/${form?.id}/leads`}
          />
          <Tab
            label="Settings"
            value="settings"
            iconPosition="start"
            icon={<SettingsOutlined />}
            component={Link}
            to={`/forms/${form?.id}/settings`}
          />
          <Tab
            label="Open"
            iconPosition="start"
            icon={<OpenInNew />}
            value="open"
            href={`${
              process.env.REACT_APP_RENDERER_URL || "https://forms.mako.ai"
            }/${form?.slug}`}
            target="_blank"
          />
        </Tabs>
      </Box>
    </NavBar>
  );
};

const Form = () => {
  const { formId } = useParams<{ formId: string }>();
  const { error, loading, data } = useQuery<GetFormQuery>(GET_FORM, {
    variables: { id: formId },
  });

  if (loading)
    return (
      <>
        <FormNavBar form={{ id: formId }} />
        <Loader />
      </>
    );
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (!data?.forms_forms_by_pk) return <pre>Not found</pre>;

  console.log("About to render form");
  return (
    <>
      <FormNavBar form={data?.forms_forms_by_pk} />
      <Routes>
        <Route path="leads/*" element={<Leads />} />
        <Route
          path="settings"
          element={<FormSettings form={data.forms_forms_by_pk} />}
        />
        {data.forms_forms_by_pk?.steps[0]?.id && (
          <Route
            path="editor"
            element={
              <Navigate
                to={`/forms/${data.forms_forms_by_pk.id}/editor/${data.forms_forms_by_pk?.steps[0]?.id}`}
                replace
              />
            }
          />
        )}
        <Route
          path="editor/:stepId?/:blockId?/:optionId?"
          element={<Editor form={data.forms_forms_by_pk} />}
        />
      </Routes>
    </>
  );
};

export default Form;
