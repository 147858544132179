import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Alert,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";

import { GET_FORM, INSERT_FORM } from "../../graphql";
import { GetFormQuery } from "../../generated/graphql";
import { neutralizeForm } from "../../utils/neutralize";
import { FileCopyOutlined } from "@mui/icons-material";

type DuplicateFormDialogProps = {
  formName: string;
  formId: string;
  onClose: () => void;
};

export const DuplicateFormDialog: React.FC<DuplicateFormDialogProps> = ({
  onClose,
  formName,
  formId,
}) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const { handleSubmit, register } = useForm({
    defaultValues: {
      name: formName + " (copy)",
    },
  });
  const [insertForm] = useMutation(INSERT_FORM);
  const [mutationError, setMutationError] = React.useState<any>(null);
  const [mutationLoading, setMutationLoading] = React.useState(false);

  const { data, loading, error } = useQuery<GetFormQuery>(GET_FORM, {
    variables: {
      id: formId,
    },
  });

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const onSubmit = handleSubmit(({ name }) => {
    setMutationError(null);
    setMutationLoading(true);

    if (!data?.forms_forms_by_pk) {
      setMutationError("Form not found");
      return;
    }

    const newFormId = v4();
    const isAdmin =
      user?.["https://hasura.io/jwt/claims"]?.["x-hasura-role"] === "admin";

    const newForm = {
      ...neutralizeForm(data.forms_forms_by_pk),
      id: newFormId,
      created_by: isAdmin ? user?.email : undefined,
      name,
      slug: newFormId,
    };

    insertForm({
      variables: {
        form: newForm,
      },
      refetchQueries: ["GetForms"],
    })
      .then((res) => {
        setMutationLoading(false);
        setTimeout(() => {
          navigate(`/forms/${res.data?.insert_forms_forms_one?.id}/editor`);
        }, 0);
        return res;
      })
      .catch((err) => {
        setMutationLoading(false);
        setMutationError(err);
      });
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={onSubmit}>
        <DialogTitle
          id="form-dialog-title"
          style={{ display: "flex", alignItems: "center", gap: "7px" }}
        >
          <FileCopyOutlined />
          Duplicate Form
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Duplicating <strong>{formName}</strong>.<br /> Enter a name for the
            new form.
          </DialogContentText>
          {mutationError && (
            <Alert severity="error">
              {JSON.stringify(mutationError, null, 2)}
            </Alert>
          )}
          <TextField
            sx={{ mt: 4 }}
            autoFocus
            margin="dense"
            id="name"
            label="Form Name"
            type="text"
            fullWidth
            {...register("name", { required: true })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disableElevation
            disabled={
              mutationLoading || loading || typeof error !== "undefined"
            }
          >
            {loading || mutationLoading ? "Loading..." : "Duplicate"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
