import { Routes, Navigate, Route } from "react-router-dom";

import Form from "./Form/Form";
import Forms from "./Forms/Forms";

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/forms" element={<Forms />} />
      <Route path="/forms/:formId/*" element={<Form />} />
      <Route path="/" element={<Navigate to="/forms" replace />} />
    </Routes>
  );
};

export default MyRoutes;
