import React, { useRef } from "react";
import { useMutation } from "@apollo/client";
import ContentEditable from "react-contenteditable";
import { useParams } from "react-router-dom";

import { UPDATE_OPTION, UPDATE_BLOCK } from "../graphql";

import {
  UpdateOptionMutation,
  UpdateOptionMutationVariables,
  UpdateBlockMutation,
  UpdateBlockMutationVariables,
} from "../generated/graphql";

import { GetFormQuery } from "../generated/graphql";
import { GET_FORM } from "../graphql";

type Block = NonNullable<
  GetFormQuery["forms_forms_by_pk"]
>["steps"][0]["blocks"][0];
type Option = NonNullable<
  GetFormQuery["forms_forms_by_pk"]
>["steps"][0]["blocks"][0]["options"][0];

type Props = {
  object: Block | Option;
  refetchQueries?: any;
};

const Editable: React.FC<Props> = ({ object }) => {
  const { formId } = useParams<{ formId: string }>();
  const [updateOption] = useMutation<
    UpdateOptionMutation,
    UpdateOptionMutationVariables
  >(UPDATE_OPTION);
  const [updateBlock] = useMutation<
    UpdateBlockMutation,
    UpdateBlockMutationVariables
  >(UPDATE_BLOCK);
  const refetchQueries = [{ query: GET_FORM, variables: { id: formId } }];

  const mutate =
    object.__typename === "forms_options" ? updateOption : updateBlock;

  const value = useRef(`${object.label}`);
  const latest = useRef(`${object.label}`);

  const handleBlur = () => {
    if (value.current !== latest.current) {
      mutate({
        variables: {
          id: object.id,
          _set: { label: value.current },
        },
        refetchQueries,
      });
    }
  };

  return (
    <ContentEditable
      html={value.current}
      onChange={(e) => (value.current = e.target.value)}
      onFocus={(e) =>
        object.__typename === "forms_options" &&
        window?.getSelection()?.selectAllChildren(e.target)
      }
      onBlur={handleBlur}
      style={{ outline: "none", cursor: "text", minWidth: 100 }}
    />
  );
};

export default Editable;
