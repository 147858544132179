import {
  DeleteOutlineOutlined,
  EditOutlined,
  FolderOutlined,
  FolderSharedOutlined,
  GroupOutlined,
  MoreHoriz,
  NoteAddOutlined,
} from "@mui/icons-material";
import { Divider, IconButton, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import IconMenu from "../../Components/IconMenu";
import type { GetFormsQuery } from "../../generated/graphql";
import { CreateFormDialog } from "./CreateFormDialog";
import { DeleteFolderDialog } from "./DeleteFolderDialog";
import { RenameFolderDialog } from "./RenameFolderDialog";
import { ShareDialog } from "./ShareDialog";

type Folder = NonNullable<GetFormsQuery["iam_folders"][0]>;

export const FolderTitle: React.FC<{ folder: Folder }> = ({ folder }) => {
  const [menu, setMenu] = useState<[number, Element | null]>([-1, null]);
  const [popup, setPopup] =
    useState<"create" | "share" | "rename" | "delete" | null>(null);

  return (
    <>
      {popup === "create" && (
        <CreateFormDialog
          folderId={folder.id}
          folderName={folder.name}
          onClose={() => setPopup(null)}
        />
      )}
      {popup === "share" && (
        <ShareDialog
          folderId={folder.id}
          folderName={folder.name}
          onClose={() => setPopup(null)}
        />
      )}
      {popup === "rename" && (
        <RenameFolderDialog
          folderId={folder.id}
          folderName={folder.name}
          onClose={() => setPopup(null)}
        />
      )}
      {popup === "delete" && (
        <DeleteFolderDialog
          folderId={folder.id}
          folderName={folder.name}
          onClose={() => setPopup(null)}
        />
      )}
      <Typography
        variant="h1"
        fontWeight={800}
        style={{ display: "flex", alignItems: "center", gap: "10px" }}
      >
        {folder.id ? <FolderSharedOutlined /> : <FolderOutlined />}
        {folder?.name}
        {folder.id && (
          <IconButton
            size="small"
            sx={{ p: 0.7 }}
            onClick={(e) => setMenu([folder.id, e.currentTarget])}
          >
            <MoreHoriz />
          </IconButton>
        )}
        <IconMenu
          id="basic-menu"
          open={menu[0] === folder.id}
          onClose={() => setMenu([-1, null])}
          anchorEl={menu[1]}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClick={() => setMenu([-1, null])}
        >
          <MenuItem onClick={() => setTimeout(() => setPopup("create"), 100)}>
            <NoteAddOutlined />
            Create Funnel
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={() => setTimeout(() => setPopup("share"), 100)}>
            <GroupOutlined />
            Share Folder
          </MenuItem>
          <MenuItem onClick={() => setTimeout(() => setPopup("rename"), 100)}>
            <EditOutlined />
            Rename Folder
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={() => setTimeout(() => setPopup("delete"), 100)}>
            <DeleteOutlineOutlined />
            Delete Folder
          </MenuItem>
        </IconMenu>
        &nbsp;
      </Typography>
    </>
  );
};
