import React from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: any): void => {
    return navigate((appState || {}).returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      cacheLocation="localstorage"
    >
      <Loader>{children}</Loader>
    </Auth0Provider>
  );
};

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Box
      sx={{
        height: "100%",
        marginTop: "-65px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
      }}
    >
      <LockTwoToneIcon fontSize="large" color="primary" />
      <Button
        variant="contained"
        disableElevation
        onClick={() =>
          loginWithRedirect({
            appState: {
              returnTo: window.location.pathname + window.location.search,
            },
          })
        }
      >
        Log In
      </Button>
      <Alert severity="warning">You must be logged-in to continue</Alert>
    </Box>
  );
};

const Loader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading)
    return (
      <Box
        sx={{
          height: "calc(100% - 65px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <CircularProgress />
        <Alert severity="info">Authentication in progress...</Alert>
      </Box>
    );

  if (!isAuthenticated) return <Login />;

  return <>{children}</>;
};

export const LogOutButton: React.FC = () => {
  const { logout } = useAuth0();
  return (
    <Button
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      Log out
    </Button>
  );
};

export default AuthProvider;
