import * as React from "react";
import { useMutation } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Alert,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { INSERT_FORM } from "../../graphql";
import { useAuth0 } from "@auth0/auth0-react";
import { CreateNewFolderOutlined } from "@mui/icons-material";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";

type CreateFormDialogProps = {
  folderId?: string;
  folderName?: string;
  onClose: () => void;
};

export const CreateFormDialog: React.FC<CreateFormDialogProps> = ({
  folderId,
  folderName,
  onClose,
}) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const { handleSubmit, reset, register } = useForm();
  const [insertForm] = useMutation(INSERT_FORM);
  const [mutationError, setMutationError] = React.useState(null);
  const [mutationLoading, setMutationLoading] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      onClose();
      reset();
    }, 300);
  };

  const onSubmit = handleSubmit((data) => {
    setMutationError(null);
    setMutationLoading(true);
    const id = v4();
    const isAdmin =
      user?.["https://hasura.io/jwt/claims"]?.["x-hasura-role"] === "admin";

    insertForm({
      variables: {
        form: {
          id,
          folder_id: folderId,
          created_by: isAdmin ? user?.email : undefined,
          slug: id,
          name: data.name,
        },
      },
      refetchQueries: ["GetForms"],
    })
      .then((res) => {
        setMutationLoading(false);
        setTimeout(() => {
          navigate(`/forms/${res.data?.insert_forms_forms_one?.id}/editor`);
        }, 0);
        return res;
      })
      .catch((e) => {
        setMutationError(e.message);
        setMutationLoading(false);
      });
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={onSubmit} autoComplete="off">
        <DialogTitle
          style={{ display: "flex", alignItems: "center", gap: "7px" }}
          id="form-dialog-title"
        >
          <CreateNewFolderOutlined /> Create a new funnel
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            To create a new funnel, please enter a name for it here. You can
            change the name later.
          </DialogContentText>
          {mutationError && (
            <Alert severity="error">
              {JSON.stringify(mutationError, null, 2)}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Form name"
            type="text"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <Typography
                  color="gray"
                  sx={{ mr: 1, whiteSpace: "nowrap" }}
                  fontSize="14px"
                >
                  {folderName || "Home"}&nbsp;/
                </Typography>
              ),
            }}
            {...register("name", { required: true })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={mutationLoading}>
            Cancel
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disableElevation
            disabled={mutationLoading}
          >
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
