import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  RadioGroup as MaterialRadioGroup,
} from "@mui/material";
import { MkRadio } from "lib";

import Editable from "./Editable";
import FloatingActions from "./FloatingActions";

import { GET_FORM } from "../graphql";

import { GetFormQuery } from "../generated/graphql";

type Block = NonNullable<
  GetFormQuery["forms_forms_by_pk"]
>["steps"][0]["blocks"][0];

const RadioGroup: React.FC<{ block: Block }> = ({ block }) => {
  const navigate = useNavigate();
  const { formId, stepId, optionId } =
    useParams<{
      module: string;
      formId: string;
      stepId: string;
      blockId: string;
      optionId: string;
    }>();
  const refetchQueries = [{ query: GET_FORM, variables: { id: formId } }];

  return (
    <FormControl component="fieldset" fullWidth key={block.id}>
      <FormLabel component="legend" style={{ width: "100%" }}>
        <Editable object={block} refetchQueries={refetchQueries} />
      </FormLabel>
      <MaterialRadioGroup name={block.id}>
        {block.options.map((o) => (
          <div
            style={{
              position: "relative",
              width: "100%",
            }}
            key={o.id}
          >
            <MkRadio
              style={{
                width: "100%",
                borderColor: optionId === o.id ? "red" : undefined,
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `/forms/${formId}/editor/${stepId}/${block.id}/${o.id}`
                );
              }}
              option={o}
              disabled
              label={<Editable object={o} refetchQueries={refetchQueries} />}
            />
            {optionId === o.id && (
              <div
                style={{
                  position: "absolute",
                  top: "calc(50% - 22px)",
                  width: 140,
                  right: -130,
                }}
              >
                <FloatingActions
                  id={o.id}
                  parentId={block.id}
                  formId={formId as string}
                  order={o.order}
                  length={block.options.length}
                  type="option"
                />
              </div>
            )}
          </div>
        ))}
      </MaterialRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;
