import React from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { IconButton, Paper } from "@mui/material";
import {
  ArrowUpward,
  ArrowDownward,
  FileCopyOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";

import {
  GET_FORM,
  INSERT_BLOCK,
  DELETE_BLOCK,
  REORDER_BLOCK,
  REORDER_OPTION,
  DELETE_OPTION,
  BLOCK_FRAGMENT,
  OPTION_FRAGMENT,
  INSERT_OPTION,
} from "../graphql";

import {
  DeleteBlockMutation,
  DeleteBlockMutationVariables,
  InsertBlockMutation,
  InsertBlockMutationVariables,
  ReorderBlockMutation,
  ReorderBlockMutationVariables,
  ReorderOptionMutation,
  ReorderOptionMutationVariables,
  DeleteOptionMutation,
  DeleteOptionMutationVariables,
  GetFormQuery,
  BlockFragmentFragment,
  OptionFragmentFragment,
  InsertOptionMutation,
  InsertOptionMutationVariables,
} from "../generated/graphql";
import { neutralizeBlock, neutralizeOption } from "../utils/neutralize";
import { v4 } from "uuid";

const FloatingActions: React.FC<{
  id: string;
  parentId: string;
  formId: string;
  type: "block" | "option";
  order: number;
  length: number;
}> = ({ id, parentId, formId, type, order, length }) => {
  const navigate = useNavigate();
  const { stepId } = useParams<{ stepId: string }>();
  const client = useApolloClient();

  const [insertBlock] =
    useMutation<InsertBlockMutation, InsertBlockMutationVariables>(
      INSERT_BLOCK
    );
  const [deleteBlock] =
    useMutation<DeleteBlockMutation, DeleteBlockMutationVariables>(
      DELETE_BLOCK
    );
  const [reorderBlock] =
    useMutation<ReorderBlockMutation, ReorderBlockMutationVariables>(
      REORDER_BLOCK
    );

  const [insertOption] =
    useMutation<InsertOptionMutation, InsertOptionMutationVariables>(
      INSERT_OPTION
    );

  const [reorderOption] =
    useMutation<ReorderOptionMutation, ReorderOptionMutationVariables>(
      REORDER_OPTION
    );
  const [deleteOption] =
    useMutation<DeleteOptionMutation, DeleteOptionMutationVariables>(
      DELETE_OPTION
    );

  const handleDeleteBlock = (e: any) => {
    // Prevent navigating back to the block since we are deleting it
    e.stopPropagation();
    return deleteBlock({
      variables: {
        block_id: id,
        step_id: parentId,
        order: order,
      },
      optimisticResponse: {
        delete_forms_blocks_by_pk: {
          __typename: "forms_blocks",
          id,
        },
        update_forms_blocks: null,
      },
      update: (cache, { data }) => {
        const existingData = cache.readQuery<GetFormQuery>({
          query: GET_FORM,
          variables: { id: formId },
        });
        const newData = {
          ...existingData,
          forms_forms_by_pk: {
            ...existingData?.forms_forms_by_pk,
            steps: existingData?.forms_forms_by_pk?.steps.map((step) => {
              if (step.id === parentId) {
                return {
                  ...step,
                  blocks: step.blocks
                    .filter(
                      (block) =>
                        block.id !== data?.delete_forms_blocks_by_pk?.id
                    )
                    .map((block, index) => ({
                      ...block,
                      order: index,
                    })),
                };
              }
              return step;
            }),
          },
        };
        cache.writeQuery({
          query: GET_FORM,
          variables: { id: formId },
          data: newData,
        });
        console.log(window.location.href.split("/").pop());
        console.log(data?.delete_forms_blocks_by_pk?.id);
        // Deselect the newly deleted block by navigating to the parent step only if still active
        if (
          window.location.href.split("/").pop() ===
          data?.delete_forms_blocks_by_pk?.id
        ) {
          navigate(`/forms/${formId}/editor/${stepId}`);
        }
      },
      // refetchQueries,
    });
  };

  const handleReorderBlock = (direction: "up" | "down") =>
    reorderBlock({
      variables: {
        block_id: id,
        step_id: parentId,
        starting_position: order,
        ending_position: direction === "up" ? order - 1 : order + 1,
      },
      optimisticResponse: {
        up: {
          __typename: "forms_blocks_mutation_response",
          returning:
            direction === "up"
              ? [
                  {
                    __typename: "forms_blocks",
                    id,
                  },
                ]
              : [],
          affected_rows: direction === "up" ? 1 : 0,
        },
        down: {
          __typename: "forms_blocks_mutation_response",
          returning:
            direction === "down"
              ? [
                  {
                    __typename: "forms_blocks",
                    id,
                  },
                ]
              : [],
          affected_rows: direction === "down" ? 1 : 0,
        },
        update_forms_blocks_by_pk: {
          __typename: "forms_blocks",
          id,
        },
      },
      update: (cache, { data }) => {
        const existingData = cache.readQuery<GetFormQuery>({
          query: GET_FORM,
          variables: { id: formId },
        });
        const newData = {
          ...existingData,
          forms_forms_by_pk: {
            ...existingData?.forms_forms_by_pk,
            steps: existingData?.forms_forms_by_pk?.steps.map((step) => {
              if (step.id === parentId) {
                return {
                  ...step,
                  blocks: step.blocks
                    .map((block) => {
                      if (block.id === id) {
                        return {
                          ...block,
                          order: direction === "up" ? order - 1 : order + 1,
                        };
                      }
                      if (
                        block.order ===
                        (direction === "up" ? order - 1 : order + 1)
                      ) {
                        return {
                          ...block,
                          order:
                            direction === "up"
                              ? block.order + 1
                              : block.order - 1,
                        };
                      }
                      return block;
                    })
                    .sort((a, b) => a.order - b.order),
                };
              }
              return step;
            }),
          },
        };
        cache.writeQuery({
          query: GET_FORM,
          variables: { id: formId },
          data: newData,
        });
      },
      // refetchQueries,
    });

  const handleDeleteOption = (e: any) => {
    // Prevent navigating back to the option since we are deleting it
    e.stopPropagation();
    deleteOption({
      variables: {
        option_id: id,
        block_id: parentId,
        order: order,
      },
      optimisticResponse: {
        delete_forms_options_by_pk: {
          __typename: "forms_options",
          id,
        },
        update_forms_options: null,
      },
      update: (cache, { data }) => {
        const existingData = cache.readQuery<GetFormQuery>({
          query: GET_FORM,
          variables: { id: formId },
        });
        const newData = {
          ...existingData,
          forms_forms_by_pk: {
            ...existingData?.forms_forms_by_pk,
            steps: existingData?.forms_forms_by_pk?.steps.map((step) => {
              if (step.id === stepId) {
                return {
                  ...step,
                  blocks: step.blocks.map((block) => {
                    if (block.id === parentId) {
                      return {
                        ...block,
                        options: block.options
                          .filter(
                            (option) =>
                              option.id !== data?.delete_forms_options_by_pk?.id
                          )
                          .map((option, index) => ({
                            ...option,
                            order: index,
                          })),
                      };
                    }
                    return block;
                  }),
                };
              }
              return step;
            }),
          },
        };
        cache.writeQuery({
          query: GET_FORM,
          variables: { id: formId },
          data: newData,
        });
        // Deselect the newly deleted option by navigating to the parent block only if still active
        if (
          window.location.href.split("/").pop() ===
          data?.delete_forms_options_by_pk?.id
        ) {
          navigate(`/forms/${formId}/editor/${stepId}/${parentId}`);
        }
      },
      // refetchQueries,
    });
  };

  const handleReorderOption = (direction: "up" | "down") =>
    reorderOption({
      variables: {
        option_id: id,
        block_id: parentId,
        starting_position: order,
        ending_position: direction === "up" ? order - 1 : order + 1,
      },
      optimisticResponse: {
        up: {
          __typename: "forms_options_mutation_response",
          returning:
            direction === "up"
              ? [
                  {
                    __typename: "forms_options",
                    id,
                  },
                ]
              : [],
          affected_rows: direction === "up" ? 1 : 0,
        },
        down: {
          __typename: "forms_options_mutation_response",
          returning:
            direction === "down"
              ? [
                  {
                    __typename: "forms_options",
                    id,
                  },
                ]
              : [],
          affected_rows: direction === "down" ? 1 : 0,
        },
        update_forms_options_by_pk: {
          __typename: "forms_options",
          id,
        },
      },
      update: (cache, { data }) => {
        const existingData = cache.readQuery<GetFormQuery>({
          query: GET_FORM,
          variables: { id: formId },
        });
        const newData = {
          ...existingData,
          forms_forms_by_pk: {
            ...existingData?.forms_forms_by_pk,
            steps: existingData?.forms_forms_by_pk?.steps.map((step) => {
              if (step.id === stepId) {
                return {
                  ...step,
                  blocks: step.blocks.map((block) => {
                    if (block.id === parentId) {
                      return {
                        ...block,
                        options: block.options
                          .map((option) => {
                            if (option.id === id) {
                              return {
                                ...option,
                                order:
                                  direction === "up"
                                    ? option.order - 1
                                    : option.order + 1,
                              };
                            }
                            if (
                              option.order ===
                              (direction === "up" ? order - 1 : order + 1)
                            ) {
                              return {
                                ...option,
                                order:
                                  direction === "up"
                                    ? option.order + 1
                                    : option.order - 1,
                              };
                            }
                            return option;
                          })
                          .sort((a, b) => a.order - b.order),
                      };
                    }
                    return block;
                  }),
                };
              }
              return step;
            }),
          },
        };
        cache.writeQuery({
          query: GET_FORM,
          variables: { id: formId },
          data: newData,
        });
      },
      // refetchQueries,
    });

  const refetchQueries = [{ query: GET_FORM, variables: { id: formId } }];

  const handleDuplicateBlock = (e: any) => {
    e.stopPropagation();
    const block = client.cache.readFragment<BlockFragmentFragment>({
      id: `forms_blocks:${id}`,
      fragment: BLOCK_FRAGMENT,
      fragmentName: "BlockFragment",
    });
    if (!block) return;
    insertBlock({
      variables: {
        block: {
          ...neutralizeBlock(block),
          step_id: stepId,
          order: block.order + 1,
        },
        step_id: stepId,
        order: block.order + 1,
      },
      refetchQueries,
    });
  };

  const handleDuplicateOption = (e: any) => {
    e.stopPropagation();
    const option = client.cache.readFragment<OptionFragmentFragment>({
      id: `forms_options:${id}`,
      fragment: OPTION_FRAGMENT,
      fragmentName: "OptionFragment",
    });
    if (!option) return;
    const newId = v4();
    insertOption({
      variables: {
        option: {
          ...neutralizeOption(option),
          id: newId,
          value: newId,
          block_id: parentId,
          order: option.order + 1,
        },
        block_id: parentId,
        order: option.order + 1,
      },
      refetchQueries,
    });
  };

  return (
    <Paper style={{ display: "inline-block" }}>
      {order > 0 && (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            type === "block"
              ? handleReorderBlock("up")
              : handleReorderOption("up");
          }}
        >
          <ArrowUpward />
        </IconButton>
      )}
      {order !== length - 1 && (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            type === "block"
              ? handleReorderBlock("down")
              : handleReorderOption("down");
          }}
        >
          <ArrowDownward />
        </IconButton>
      )}
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          type === "block" ? handleDuplicateBlock(e) : handleDuplicateOption(e);
        }}
      >
        <FileCopyOutlined />
      </IconButton>
      {!(type === "option" && length < 3) && (
        <IconButton
          size="small"
          onClick={(e) =>
            type === "block" ? handleDeleteBlock(e) : handleDeleteOption(e)
          }
        >
          <DeleteOutline />
        </IconButton>
      )}
    </Paper>
  );
};

export default FloatingActions;
