import React from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import {
  DELETE_FOLDER_USER,
  GET_FOLDER_USERS,
  INSERT_FOLDER_USER,
} from "../../graphql";
import { Delete, GroupOutlined } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { GetFolderUsersQuery } from "../../generated/graphql";
import { formatDistance } from "date-fns";

type ShareDialogProps = {
  folderId: string;
  folderName: string;
  onClose: () => void;
};

export const ShareDialog: React.FC<ShareDialogProps> = ({
  folderId,
  folderName,
  onClose,
}) => {
  const [open, setOpen] = React.useState(true);
  const [mutationError, setMutationError] = React.useState(null);
  const [mutationLoading, setMutationLoading] = React.useState(false);
  const { handleSubmit, reset, register } = useForm();

  const { data } = useQuery<GetFolderUsersQuery>(GET_FOLDER_USERS, {
    variables: {
      id: folderId,
    },
  });
  const [insertFolderUser] = useMutation(INSERT_FOLDER_USER);
  const [deleteFolderUser] = useMutation(DELETE_FOLDER_USER);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const onSubmit = handleSubmit((data) => {
    setMutationError(null);
    setMutationLoading(true);
    insertFolderUser({
      variables: {
        object: {
          folder_id: folderId,
          user_id: data.email,
        },
      },
      refetchQueries: ["GetFolderUsers"],
    })
      .catch((e) => {
        setMutationLoading(false);
        setMutationError(e.message);
      })
      .then(() => {
        setMutationLoading(false);
        reset();
      });
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        style={{ display: "flex", alignItems: "center", gap: "7px" }}
      >
        <GroupOutlined /> Share folder
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Invite other users to <strong>{folderName}</strong> to collaborate on
          funnels and leads.
        </DialogContentText>
        {mutationError && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {mutationError}
          </Alert>
        )}
        <form onSubmit={onSubmit} autoComplete="off">
          <TextField
            autoFocus
            {...register("email", { required: true })}
            fullWidth
            margin="dense"
            id="email"
            label="Invite by email"
            type="email"
            placeholder="john.doe@example.com"
            autoComplete="off"
            disabled={mutationLoading}
            InputProps={{
              endAdornment: (
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={mutationLoading}
                  disableElevation
                >
                  Invite
                </Button>
              ),
            }}
            sx={{ mt: 3, mb: 2 }}
          />
        </form>
        <List>
          {data?.iam_folders_by_pk?.folders_users?.map((user) => (
            <ListItem key={user.user_id}>
              <ListItemText
                primary={user.user_id}
                secondary={formatDistance(
                  new Date(user.created_at),
                  Date.now(),
                  {
                    addSuffix: true,
                  }
                )}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() =>
                    deleteFolderUser({
                      variables: {
                        folder_id: folderId,
                        user_id: user.user_id,
                      },
                      refetchQueries: ["GetFolderUsers"],
                    })
                  }
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
