import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  GetFormQuery,
  UpdateFormMutation,
  UpdateFormMutationVariables,
} from "../generated/graphql";
import { UPDATE_FORM } from "../graphql";
import FormatButtons from "./FormatButtons";

type Form = NonNullable<GetFormQuery["forms_forms_by_pk"]>;

type HeaderSettingsProps = {
  form: Form;
};

const HeaderSettings: React.FC<HeaderSettingsProps> = ({ form }) => {
  const [updateForm] =
    useMutation<UpdateFormMutation, UpdateFormMutationVariables>(UPDATE_FORM);

  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      header_image_url: form.header_image_url,
      header_image_max_height: form.header_image_max_height,
      header_image_max_width: form.header_image_max_width,
      show_progress_bar: form.show_progress_bar,
    },
  });

  const onSubmit = handleSubmit((data) => {
    console.log(data);
    updateForm({
      variables: {
        id: form.id,
        _set: {
          header_image_url: data.header_image_url,
          header_image_max_height: data.header_image_max_height,
          header_image_max_width: data.header_image_max_width,
          show_progress_bar: data.show_progress_bar,
        },
      },
    });
  });

  return (
    <>
      <Typography variant="h3">Edit header</Typography>
      <FormatButtons />
      <form onSubmit={onSubmit} autoComplete="off">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
          }}
        >
          <TextField
            {...register("header_image_url")}
            label="Header image URL"
            size="small"
            margin="dense"
            fullWidth
          />
          <TextField
            {...register("header_image_max_width")}
            type="number"
            label="Header image max width"
            size="small"
            margin="dense"
            fullWidth
          />
          <TextField
            {...register("header_image_max_height")}
            type="number"
            label="Header image max height"
            size="small"
            margin="dense"
            fullWidth
          />
          {/* Checkbox to show and hide the progress bar */}
          <Controller
            name="show_progress_bar"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ width: 1, mx: 2 }}
                label="Show progress bar"
                control={
                  <Switch
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    size="small"
                  />
                }
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            disableElevation
            color="primary"
          >
            Save
          </Button>
        </Box>
      </form>
    </>
  );
};

export default HeaderSettings;
