import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  MenuItem,
  Tooltip,
  SvgIcon,
  Divider,
  Alert,
  Snackbar,
} from "@mui/material";
import {
  AlternateEmail,
  Adjust,
  Title,
  Html,
  ImportExport,
  KeyboardArrowDown,
  Notes,
  Tag,
  CheckBoxOutlined,
  TextFields,
  PersonOutlineOutlined,
  GroupOutlined,
  PhoneOutlined,
  TouchAppOutlined,
  LocationOn,
  LocationOnOutlined,
} from "@mui/icons-material";
import { ReactComponent as TextInput } from "../assets/TextInput.svg";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { GET_FORM, INSERT_BLOCK } from "../graphql";
import IconMenu from "../Components/IconMenu";

import {
  Forms_Blocks_Insert_Input,
  GetFormQuery,
  InsertBlockMutation,
  InsertBlockMutationVariables,
} from "../generated/graphql";

type FormType = NonNullable<GetFormQuery["forms_forms_by_pk"]>;

const TextInputIcon = () => (
  <SvgIcon viewBox="0 0 48 42">
    <TextInput />
  </SvgIcon>
);

const BlocksDock: React.FC<{ form: FormType }> = ({ form }) => {
  const { formId, stepId, blockId } =
    useParams<{ formId: string; stepId?: string; blockId?: string }>();
  const navigate = useNavigate();
  const [menu, setMenu] = useState<[number, Element | null]>([-1, null]);
  const [error, setError] = useState<string | null>(null);
  const [insertBlock] =
    useMutation<InsertBlockMutation, InsertBlockMutationVariables>(
      INSERT_BLOCK
    );

  const step = form?.steps?.find((s) => s.id === stepId);
  const currentBlock = step?.blocks?.find((b) => b.id === blockId);

  const basePayload = {
    step_id: stepId,
    order:
      typeof currentBlock?.order === "number"
        ? currentBlock?.order + 1
        : step?.blocks?.length || 0,
  };

  console.log({
    basePayload,
    currentBlock,
  });

  const handleAddBlock = (block: Forms_Blocks_Insert_Input) => {
    const id = uuidv4();
    const options = block.options?.data.length
      ? block.options.data.map((o) => ({
          ...o,
          id: uuidv4(),
        }))
      : [];
    const props = block.props ? block.props : {};
    const label = block.label ? block.label : "";

    const variables = {
      ...basePayload,
      block: {
        ...basePayload,
        ...block,
        id,
        props,
        options: {
          data: options,
        },
      },
    };

    console.log({ variables });

    const optimisticResponse = {
      update_forms_blocks: {
        affected_rows: 0,
      },
      insert_forms_blocks_one: {
        ...basePayload,
        ...block,
        __typename: "forms_blocks",
        id,
        created_at: new Date().toISOString(),
        props,
        options: options.map((o) => ({
          ...o,
          __typename: "forms_options",
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          block_id: id,
        })),
        label,
      } as any,
    };

    insertBlock({
      variables,
      optimisticResponse,
      update(cache, { data }) {
        const existingData = cache.readQuery<GetFormQuery>({
          query: GET_FORM,
          variables: { id: formId },
        });

        if (existingData) {
          const newData = {
            ...existingData,
            forms_forms_by_pk: {
              ...existingData?.forms_forms_by_pk,
              steps: existingData?.forms_forms_by_pk?.steps?.map((s) => {
                if (s.id === stepId) {
                  return {
                    ...s,
                    blocks: [
                      ...s.blocks
                        .filter(
                          (b) => b.id !== data?.insert_forms_blocks_one?.id
                        )
                        .map((b) => ({
                          ...b,
                          order:
                            data?.insert_forms_blocks_one?.order &&
                            b.order >= data?.insert_forms_blocks_one?.order
                              ? b.order + 1
                              : b.order,
                        })),
                      data?.insert_forms_blocks_one,
                    ].sort((a, b) => (a?.order || 0) - (b?.order || 0)),
                  };
                }
                return s;
              }),
            },
          };
          // Optimistically update the cache
          cache.writeQuery({
            query: GET_FORM,
            variables: { id: formId },
            data: newData,
          });
        }

        // Let the cache update first before navigating to the new block
        setTimeout(() => {
          navigate(
            `/forms/${form?.id}/editor/${stepId}/${data?.insert_forms_blocks_one?.id}`
          );
        }, 50);
      },
      refetchQueries: [{ query: GET_FORM, variables: { id: formId } }],
    }).catch((e) => setError(e.message));
  };

  const actions: {
    icon: any;
    name: string;
    block: Forms_Blocks_Insert_Input;
  }[] = [
    {
      icon: Title,
      name: "Text",
      block: {
        block_type_id: "text",
        label: "<h1>How can we help today?</h1>",
      },
    },
    {
      icon: Adjust,
      name: "Radio",
      block: {
        block_type_id: "input-radio",
        label: "What is your favourite colour?",
        options: {
          data: [
            { label: "Blue", value: "blue", icon: null, order: 0 },
            { label: "Red", value: "red", icon: null, order: 1 },
          ],
        },
      },
    },
    {
      icon: CheckBoxOutlined,
      name: "Checkbox",
      block: {
        block_type_id: "input-checkbox",
        props: { label: "Privacy Policy", required: true },
      },
    },
    {
      icon: TouchAppOutlined,
      name: "Button",
      block: {
        block_type_id: "button",
        props: {
          label: "Submit",
          behavior: "next",
          variant: "contained",
          color: "primary",
          size: "large",
          fullWidth: true,
          disabled: false,
        },
      },
    },
    {
      icon: ImportExport,
      name: "Fetch",
      block: {
        block_type_id: "fetch",
        props: {
          url: "https://www.mako.ai",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            data: {
              name: "Mako",
              age: "42",
            },
          },
        },
      },
    },
    {
      icon: LocationOnOutlined,
      name: "Address",
      block: {
        block_type_id: "input-address",
        props: {
          name: "address",
          label: "Address",
          placeholder: "Enter address",
          gmapsApiKey: "AIzaSyA9p1wW6WoQHw2AvWnpSi4-f9-I1V76ggc",
        },
      },
    },
    {
      icon: LocationOn,
      name: "Address",
      block: {
        block_type_id: "input-address-full",
        props: {
          name: "address",
          label: "Address",
          placeholder: "Enter address",
          gmapsApiKey: "AIzaSyA9p1wW6WoQHw2AvWnpSi4-f9-I1V76ggc",
        },
      },
    },
    {
      icon: Html,
      name: "HTML",
      block: {
        block_type_id: "html",
        props: {
          html: "<h1>How can we help today?</h1>",
        },
      },
    },
  ];

  const textInputActions = [
    {
      icon: PersonOutlineOutlined,
      name: "First name",
      block: {
        block_type_id: "input-text",
        props: {
          type: "text",
          name: "first_name",
          label: "First name",
          placeholder: "Enter your first name",
          defaultValue: "",
          size: "medium",
          margin: "normal",
          variant: "outlined",
          required: true,
          autoComplete: "given-name",
          autoFocus: false,
          disabled: false,
          fullWidth: true,
          startAdornment: "",
          endAdornment: "",
          helperText: "",
          multiline: false,
          rows: null,
          maxRows: null,
          minRows: null,
        },
      },
    },
    {
      icon: GroupOutlined,
      name: "Last name",
      block: {
        block_type_id: "input-text",
        props: {
          type: "text",
          name: "last_name",
          label: "Last name",
          placeholder: "Enter your last name",
          defaultValue: "",
          size: "medium",
          margin: "normal",
          variant: "outlined",
          required: true,
          autoComplete: "family-name",
          autoFocus: false,
          disabled: false,
          fullWidth: true,
          startAdornment: "",
          endAdornment: "",
          helperText: "",
          multiline: false,
          rows: null,
          maxRows: null,
          minRows: null,
        },
      },
    },
    {
      icon: AlternateEmail,
      name: "Email",
      block: {
        block_type_id: "input-text",
        props: {
          type: "email",
          name: "email",
          label: "Email",
          placeholder: "Enter your email",
          defaultValue: "",
          size: "medium",
          margin: "normal",
          variant: "outlined",
          required: true,
          autoComplete: "email",
          autoFocus: false,
          disabled: false,
          fullWidth: true,
          startAdornment: "",
          endAdornment: "",
          helperText: "",
          multiline: false,
          rows: null,
          maxRows: null,
          minRows: null,
        },
      },
    },
    {
      icon: PhoneOutlined,
      name: "Phone",
      block: {
        ...basePayload,
        block_type_id: "input-phone",
        label: "Phone number",
      },
    },
    {
      icon: TextFields,
      name: "Basic text",

      block: {
        ...basePayload,
        block_type_id: "input-text",
        props: {
          type: "text",
          name: "text",
          label: "Text input",
          placeholder: "",
          defaultValue: "",
          size: "medium",
          margin: "normal",
          variant: "outlined",
          required: true,
          autoComplete: "off",
          autoFocus: false,
          disabled: false,
          fullWidth: true,
          startAdornment: "",
          endAdornment: "",
          helperText: "",
          multiline: false,
          rows: null,
          maxRows: null,
          minRows: null,
        },
      },
    },
    {
      icon: Notes,
      name: "Multi-line",
      block: {
        block_type_id: "input-text",
        props: {
          type: "text",
          name: "multiline",
          label: "Comments",
          placeholder: "What should we know?",
          defaultValue: "",
          size: "medium",
          margin: "normal",
          variant: "outlined",
          required: true,
          autoComplete: "off",
          autoFocus: false,
          disabled: false,
          fullWidth: true,
          startAdornment: "",
          endAdornment: "",
          helperText: "",
          multiline: true,
          rows: null,
          maxRows: null,
          minRows: 4,
        },
      },
    },
    {
      icon: Tag,
      name: "Number",
      block: {
        block_type_id: "input-text",
        props: {
          type: "number",
          name: "number",
          label: "Number",
          placeholder: "Enter a number",
          defaultValue: "",
          size: "medium",
          margin: "normal",
          variant: "outlined",
          required: true,
          autoComplete: null,
          autoFocus: false,
          disabled: false,
          fullWidth: true,
          startAdornment: "",
          endAdornment: "",
          helperText: "",
          multiline: false,
          rows: null,
          maxRows: null,
          minRows: null,
        },
      },
    },
  ];

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          margin: "0 auto",
        }}
      >
        {actions.map((action) => (
          <Tooltip title={action.name} key={action.name} arrow>
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleAddBlock(action.block);
              }}
              sx={{ color: "text.secondary", minWidth: 0, m: 0 }}
            >
              <action.icon />
            </Button>
          </Tooltip>
        ))}
        <Divider orientation="vertical" flexItem sx={{ m: 0.5 }} />
        <Tooltip title="Text inputs" arrow>
          <Button
            onClick={(e) => setMenu([1, e.currentTarget])}
            endIcon={<KeyboardArrowDown />}
            variant="text"
            disableElevation
            sx={{ color: "text.secondary", minWidth: 0, m: 0 }}
          >
            <TextInputIcon />
          </Button>
        </Tooltip>
      </Box>

      <IconMenu
        id="basic-menu"
        open={menu[0] === 1}
        onClose={() => setMenu([-1, null])}
        anchorEl={menu[1]}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {textInputActions.map((action) => (
          <MenuItem
            key={action.name}
            onClick={(e) => {
              e.stopPropagation();
              handleAddBlock(action.block);
              setMenu([-1, null]);
            }}
          >
            <action.icon />
            {action.name}
          </MenuItem>
        ))}
      </IconMenu>
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error.length > 0}
          autoHideDuration={3000}
          onClose={() => setError(null)}
          message={error}
        >
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default BlocksDock;
