// This utility is used to prepare a graphql object for duplication
// It is used to remove the id field and the __typename from a graphql object

import {
  Forms_Blocks_Insert_Input,
  Forms_Display_Conditions_Insert_Input,
  Forms_Steps_Insert_Input,
  Forms_Options_Insert_Input,
  Forms_Forms_Insert_Input,
  GetFormQuery,
} from "../generated/graphql";

type Form = NonNullable<GetFormQuery["forms_forms_by_pk"]>;
type Step = NonNullable<Form["steps"]>[number];
type Block = NonNullable<Step["blocks"]>[number];
type DisplayCondition = NonNullable<Step["display_conditions"]>[number];
type Option = NonNullable<Block["options"]>[number];

export const neutralize: any = (obj: any) => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(neutralize);
  }

  const result: any = {};
  for (const key of Object.keys(obj)) {
    if (key === "id" || key === "__typename") {
      continue;
    }
    result[key] = neutralize(obj[key]);
  }
  return result;
};

export const neutralizeOption = (option: Option) => {
  const { id, __typename, created_at, updated_at, block_id, ...rest } = option;
  const newOption: Forms_Options_Insert_Input = {
    ...rest,
  };
  return newOption;
};

export const neutralizeOptions = (options: Option[]) => {
  const newOptions: Forms_Options_Insert_Input[] =
    options.map(neutralizeOption);
  return newOptions;
};

export const neutralizeBlock = (block: Block) => {
  const { id, __typename, created_at, options, ...rest } = block;

  const newBlock: Forms_Blocks_Insert_Input = {
    ...rest,
    options: {
      data: neutralizeOptions(options),
    },
  };
  return newBlock;
};

export const neutralizeBlocks = (blocks: Block[]) => {
  const newBlocks: Forms_Blocks_Insert_Input[] = blocks.map(neutralizeBlock);
  return newBlocks;
};

export const neutralizeDisplayCondition = (
  displayCondition: DisplayCondition
) => {
  const { id, __typename, block, ...rest } = displayCondition;
  const newDisplayCondition: Forms_Display_Conditions_Insert_Input = {
    ...rest,
  };
  return newDisplayCondition;
};

export const neutralizeStep = (step: Step) => {
  const { id, __typename, form_id, blocks, display_conditions, ...rest } = step;

  const newStep: Forms_Steps_Insert_Input = {
    ...rest,
    blocks: {
      data: neutralizeBlocks(blocks),
    },
  };
  return newStep;
};

export const neutralizeForm = (form: Form) => {
  const {
    id,
    __typename,
    created_at,
    updated_at,
    created_by,
    steps,
    leads,
    ...rest
  } = form;
  const newForm: Forms_Forms_Insert_Input = {
    ...rest,
    steps: {
      data: steps.map(neutralizeStep),
    },
  };
  return newForm;
};
