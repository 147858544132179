import { useQuery } from "@apollo/client";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { GetFoldersQuery } from "../generated/graphql";
import { GET_FOLDERS } from "../graphql";

export const FolderPicker: React.FC<{ field: any }> = ({ field }) => {
  const { loading, data } = useQuery<GetFoldersQuery>(GET_FOLDERS);
  if (loading) return <Select />;

  const folders = [
    { id: null, name: "My forms" },
    ...(data?.iam_folders || []),
  ];

  return (
    <>
      <FormControl fullWidth size="small">
        <InputLabel>Folder</InputLabel>
        <Select label="Folder" fullWidth size="small" {...field}>
          {folders.map((f) => (
            <MenuItem key="1" value={f.id}>
              {f.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
