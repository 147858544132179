import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useMutation } from "@apollo/client";
import { DELETE_FOLDER } from "../../graphql";
import { DeleteOutlineOutlined } from "@mui/icons-material";

type DeleteFolderDialogProps = {
  folderId: string;
  folderName: string;
  onClose: () => void;
};

export const DeleteFolderDialog: React.FC<DeleteFolderDialogProps> = ({
  folderId,
  folderName,
  onClose,
}) => {
  const [open, setOpen] = React.useState(true);

  const [deleteFolder] = useMutation(DELETE_FOLDER);
  const [mutationError, setMutationError] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const onConfirm = () => {
    setMutationError(null);

    deleteFolder({
      variables: {
        id: folderId,
      },
      refetchQueries: ["GetForms"],
    })
      .catch((e) => setMutationError(e.message))
      .then(() => {
        handleClose();
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{ display: "flex", alignItems: "center", gap: "7px" }}
          id="alert-dialog-title"
        >
          <DeleteOutlineOutlined /> Delete folder
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the folder{" "}
            <strong>{folderName}</strong>?<br />
            <br />
            Each form in the folder will be moved to the form creator's personal
            folder and sharing will be disabled. No form or lead data will be
            deleted.
          </DialogContentText>
          {mutationError && (
            <DialogContentText color="error">
              {JSON.stringify(mutationError, null, 2)}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={onConfirm}
            autoFocus
            color="error"
            variant="contained"
            disableElevation
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
