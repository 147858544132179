// This component opens a drawer to show the details of a lead
import { useState, useEffect } from "react";
import { MkPre } from "lib";
import {
  Box,
  Drawer,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_LEAD } from "../../graphql";
import { GetLeadQuery } from "../../generated/graphql";

import { GetFormQuestionsQuery } from "../../generated/graphql";

type LeadDrawerProps = {
  questions?: GetFormQuestionsQuery["forms_blocks"];
  onClose: () => void;
};

const LeadDrawer: React.FC<LeadDrawerProps> = ({ questions, onClose }) => {
  const [open, setOpen] = useState(false);
  // Render Drawer animation
  useEffect(() => setOpen(true), []);

  const handleClose = () => {
    setOpen(false);
    setTimeout(onClose, 300);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { width: ["100%", "80%"], maxWidth: 700 },
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Typography variant="h1" fontWeight={600} sx={{ mb: 4 }}>
          Lead Details
        </Typography>
        <DrawerContent questions={questions} />
      </Box>
    </Drawer>
  );
};

type DrawerContentProps = {
  questions?: GetFormQuestionsQuery["forms_blocks"];
};

const DrawerContent: React.FC<DrawerContentProps> = ({ questions }) => {
  const { leadId } = useParams<{ leadId: string }>();
  const { data, loading, error } = useQuery<GetLeadQuery>(GET_LEAD, {
    variables: { id: leadId },
  });

  if (loading)
    return (
      <>
        {questions?.map((q) => (
          <div key={q.id}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton
              variant="text"
              sx={{ fontSize: "2rem", paddingBottom: 2, marginBottom: 2 }}
            />
          </div>
        ))}
      </>
    );
  if (error) return <MkPre>{JSON.stringify(error, null, 2)}</MkPre>;

  const lead = data?.forms_leads_by_pk;
  const flatLead = data?.forms_leads_by_pk?.flat_lead;

  return (
    <>
      {
        // Render the lead details
        questions?.map((question) => {
          const answer = flatLead?.answers[question.id];
          return (
            <Box key={question.id} sx={{ mb: 2, maxWidth: 750 }}>
              <Typography color="text.secondary" variant="caption">
                {answer?.question_label
                  .replace(/<[^>]*>?/gm, " ")
                  .replace(/\s+/, " ")
                  .trim()}
              </Typography>
              {answer?.option_label && (
                <Typography variant="body1">
                  {answer?.option_label
                    .replace(/<[^>]*>?/gm, " ")
                    .replace(/\s+/, " ")
                    .trim()}
                </Typography>
              )}
              <Typography
                variant="body1"
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {answer?.value?.value?.toString()}
              </Typography>
            </Box>
          );
        })
      }
      <Typography variant="h2" fontWeight={600} sx={{ mt: 4, mb: 2 }}>
        Meta data
      </Typography>
      <TableContainer
        component={Paper}
        className="card-table"
        elevation={0}
        sx={{
          width: "100%",
          borderColor: "divider",
          borderWidth: 1,
          borderRadius: 2,
          borderStyle: "solid",
          borderBottom: 0,
        }}
      >
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>User Agent</TableCell>
              <TableCell>{lead?.user_agent || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Referrer</TableCell>
              <TableCell>{lead?.referer_url || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Landing page</TableCell>
              <TableCell>{lead?.landing_page_url || "-"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {Object.keys({ ...lead?.cookies }).length > 0 && (
        <>
          <Typography variant="h2" fontWeight={600} sx={{ mt: 4, mb: 2 }}>
            Cookies
          </Typography>
          <TableContainer
            component={Paper}
            className="card-table"
            elevation={0}
            sx={{
              width: "100%",
              borderColor: "divider",
              borderWidth: 1,
              borderRadius: 2,
              borderStyle: "solid",
              borderBottom: 0,
            }}
          >
            <Table size="small">
              <TableBody>
                {Object.keys({ ...lead?.cookies })?.map((key) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{lead?.cookies?.[key] || "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {process.env.NODE_ENV === "development" && (
        <div style={{ width: 500 }}>
          <MkPre dark>{JSON.stringify({ data, questions }, null, 2)}</MkPre>
        </div>
      )}
    </>
  );
};

export default LeadDrawer;
