import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { neutralizeStep } from "../utils/neutralize";
import {
  GET_FORM,
  INSERT_STEP,
  DELETE_STEP,
  REORDER_STEP,
  UPDATE_STEP,
} from "../graphql";
import {
  GetFormQuery,
  InsertStepMutation,
  InsertStepMutationVariables,
  DeleteStepMutation,
  DeleteStepMutationVariables,
  ReorderStepMutation,
  ReorderStepMutationVariables,
  UpdateStepMutation,
  UpdateStepMutationVariables,
} from "../generated/graphql";

type FormData = any;
type Form = GetFormQuery["forms_forms_by_pk"];
type Step = NonNullable<GetFormQuery["forms_forms_by_pk"]>["steps"][0];

const Steps: React.FC<{ form?: Form }> = ({ form }) => {
  const navigate = useNavigate();

  const [insertStep] =
    useMutation<InsertStepMutation, InsertStepMutationVariables>(INSERT_STEP);

  const [deleteStep] =
    useMutation<DeleteStepMutation, DeleteStepMutationVariables>(DELETE_STEP);

  const [reorderStep] =
    useMutation<ReorderStepMutation, ReorderStepMutationVariables>(
      REORDER_STEP
    );
  const [updateStep] =
    useMutation<UpdateStepMutation, UpdateStepMutationVariables>(UPDATE_STEP);

  const { handleSubmit, register, unregister, getValues, reset } =
    useForm<FormData>();

  const { stepId } = useParams<{ stepId: string }>();

  const [addStep, setAddStep] = useState(false);
  const [menu, setMenu] = useState<[number, Element | null]>([-1, null]);
  const [rename, setRename] = useState(null);
  const [mutationError, setMutationError] = useState(null);

  const refetchQueries = [{ query: GET_FORM, variables: { id: form?.id } }];

  const onSubmit = handleSubmit((data) => {
    setMutationError(null);
    const id = uuidv4();
    const variables = {
      id,
      name: data.name,
      form_id: form?.id,
      slug: id,
      order: form?.steps?.length,
    };
    const optimisticResponse = {
      insert_forms_steps_one: {
        __typename: "forms_steps",
        ...variables,
        display_conditions: [],
        blocks: [],
      },
    } as any;

    insertStep({
      variables: { step: variables },
      optimisticResponse,
      update(cache, { data }) {
        const existingFormData = cache.readQuery<GetFormQuery>({
          query: GET_FORM,
          variables: { id: form?.id },
        });
        if (!existingFormData)
          return window.alert(
            "existingFormData: " + JSON.stringify(existingFormData, null, 2)
          );
        const newFormData = {
          ...existingFormData,
          forms_forms_by_pk: {
            ...existingFormData?.forms_forms_by_pk,
            steps: [
              ...(existingFormData?.forms_forms_by_pk?.steps.filter(
                (s) => s.id !== data?.insert_forms_steps_one?.id
              ) || []),
              data?.insert_forms_steps_one,
            ],
          },
        };

        cache.writeQuery({
          query: GET_FORM,
          variables: { id: form?.id },
          data: newFormData,
        });
        // Let the cache update before navigating to the new step
        setTimeout(() => {
          navigate(
            `/forms/${form?.id}/editor/${data?.insert_forms_steps_one?.id}`
          );
        }, 0);
        setAddStep(false);
      },
      // refetchQueries,
    })
      .then((r) => reset())
      .catch((e) => {
        setAddStep(true);
        setMutationError(e.message);
      });
  });

  const onRename = handleSubmit((data) => {
    setMutationError(null);
    setRename(null);
    updateStep({
      variables: { id: data.id, _set: { name: data.name } },
      refetchQueries,
    });
  });

  const handleDelete = (step: Step) => {
    const optimisticResponse: DeleteStepMutation = {
      delete_forms_steps_by_pk: {
        __typename: "forms_steps",
        id: step.id,
        order: step.order,
      },
      update_forms_steps: {
        __typename: "forms_steps_mutation_response",
        affected_rows: 0,
        returning: [],
      },
    };
    deleteStep({
      variables: { id: step.id, form_id: form?.id, order: step.order },
      optimisticResponse,
      refetchQueries,
      update(cache, { data }) {
        const existingFormData = cache.readQuery<GetFormQuery>({
          query: GET_FORM,
          variables: { id: form?.id },
        });

        if (!data) return;
        if (!data.delete_forms_steps_by_pk) return;
        const newFormData = {
          ...existingFormData,
          forms_forms_by_pk: {
            ...existingFormData?.forms_forms_by_pk,
            steps:
              existingFormData?.forms_forms_by_pk?.steps
                ?.filter(
                  (step) => step?.id !== data?.delete_forms_steps_by_pk?.id
                )
                .map((step) => {
                  return {
                    ...step,
                    order:
                      step.order > (data?.delete_forms_steps_by_pk?.order || -1)
                        ? step.order - 1
                        : step.order,
                  };
                }) || [],
          },
        };
        cache.writeQuery({
          query: GET_FORM,
          variables: { id: form?.id },
          data: newFormData,
        });
        // Move await from step
        navigate(`/forms/${form?.id}/editor`);
      },
    });
    setMenu([-1, null]);
  };

  const handleRename = (step: Step) => {
    setMenu([-1, null]);
    unregister(["id", "name"]);
    setRename(step.id);
  };

  const handleReorder = (step: Step, position: number) => {
    setMenu([-1, null]);
    reorderStep({
      variables: {
        form_id: step.form_id,
        step_id: step.id,
        starting_position: step.order,
        ending_position: position,
      },
      refetchQueries,
    });
  };

  const handleDuplicate = (step: Step) => {
    setMenu([-1, null]);
    const id = uuidv4();
    const newStep = {
      ...neutralizeStep(step),
      id,
      form_id: step.form_id,
      name: `${step.name} (copy)`,
      slug: id,
      order: form?.steps?.length,
    };

    insertStep({
      variables: {
        step: newStep,
      },
    }).then((r) =>
      reorderStep({
        variables: {
          form_id: step.form_id,
          step_id: r.data?.insert_forms_steps_one?.id,
          starting_position:
            r.data?.insert_forms_steps_one?.order || form?.steps.length || 0,
          ending_position: step.order + 1,
        },
        refetchQueries,
      })
    );
  };

  return (
    <>
      <List>
        {form?.steps.map((step, i) =>
          rename === step.id ? (
            <ListItem key={step.id} dense>
              <form onSubmit={onRename} autoComplete="off">
                <TextField
                  {...register("name", { required: true })}
                  id="name"
                  name="name"
                  defaultValue={step.name}
                  margin="dense"
                  onBlur={() => {
                    onRename(getValues());
                  }}
                  autoFocus
                  fullWidth
                />

                <input
                  {...register("id", { required: true })}
                  type="hidden"
                  name="id"
                  value={step.id}
                />
              </form>
            </ListItem>
          ) : (
            <ListItem
              key={step.id}
              disablePadding
              secondaryAction={
                <>
                  <IconButton
                    onClick={(e) => setMenu([i, e.currentTarget])}
                    edge="end"
                    aria-label="delete"
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    open={menu[0] === i}
                    onClose={() => setMenu([-1, null])}
                    anchorEl={menu[1]}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {step.order > 0 && (
                      <MenuItem
                        onClick={() =>
                          handleReorder(step, form.steps[i - 1].order)
                        }
                      >
                        🔼&nbsp;&nbsp;Move up
                      </MenuItem>
                    )}
                    {step.order < form.steps.length - 1 && (
                      <MenuItem
                        onClick={() =>
                          handleReorder(step, form.steps[i + 1].order)
                        }
                      >
                        🔽&nbsp;&nbsp;Move down
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => handleDuplicate(step)}>
                      📑&nbsp;&nbsp;Duplicate
                    </MenuItem>
                    <MenuItem onClick={() => handleRename(step)}>
                      ✏️&nbsp;&nbsp;Rename
                    </MenuItem>
                    <MenuItem onClick={() => handleDelete(step)}>
                      🗑&nbsp;&nbsp;Delete
                    </MenuItem>
                  </Menu>
                </>
              }
            >
              <ListItemButton
                component={Link}
                selected={stepId === step.id}
                to={`/forms/${form.id}/editor/${step.id}`}
                dense={form.steps.length > 9}
              >
                <ListItemText primary={`${step.order}. ${step.name}`} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      <form onSubmit={onSubmit} autoComplete="off">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            gap: "12px",
          }}
        >
          {!addStep && (
            <Button
              fullWidth
              disableElevation
              color="primary"
              onClick={() => setAddStep(true)}
            >
              Add step
            </Button>
          )}
          {addStep && (
            <>
              <Typography variant="h3">Add new step</Typography>
              {mutationError && <Alert severity="error">{mutationError}</Alert>}
              <TextField
                {...register("name", { required: true })}
                id="name"
                label="Name"
                size="small"
                autoFocus
                margin="dense"
                fullWidth
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                color="primary"
              >
                Save
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => setAddStep(false)}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
      </form>
    </>
  );
};

export default Steps;
