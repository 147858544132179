import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  MkCheckboxGroup,
  MkFetch,
  MkTextField,
  MkPhoneInput,
  MkButton,
  MkCheckbox,
  MkAddress,
  MkAddressFull,
  MkHtml,
} from "lib";

import Editable from "./Editable";
import RadioGroup from "./RadioGroup";
import FloatingActions from "./FloatingActions";

import { GetFormQuery, GetLeadsQuery } from "../generated/graphql";

type FormType = NonNullable<GetFormQuery["forms_forms_by_pk"]>;
type LeadType = NonNullable<GetLeadsQuery["forms_forms_by_pk"]>["leads"][0];

interface StepProps {
  form: FormType;
  lead?: LeadType;
}

const Step: React.FC<StepProps> = ({ form, lead }) => {
  const navigate = useNavigate();
  const { stepId, blockId, optionId } =
    useParams<{
      stepId: string;
      blockId: string;
      optionId: string;
    }>();
  const { control } = useForm<FormData>({
    defaultValues: {},
  });

  console.log({ stepId });
  const step = form?.steps.find((step) => step.id === stepId);

  return (
    <>
      {step?.blocks?.map((block) => (
        <div
          key={block.id}
          style={{
            border: "1px solid",
            borderColor: blockId === block.id ? "#f44336" : "transparent",
            margin: "0 -20px",
            padding: "0 20px",
            position: "relative",
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/forms/${form?.id}/editor/${step.id}/${block.id}`);
          }}
        >
          {blockId === block.id && !optionId && (
            <div
              style={{
                position: "absolute",
                top: "calc(50% - 16px)",
                width: 140,
                right: -130,
              }}
            >
              <FloatingActions
                id={block.id}
                parentId={step.id}
                formId={form?.id}
                order={block.order}
                length={step.blocks?.length}
                type="block"
              />
            </div>
          )}
          {(() => {
            switch (block.block_type_id) {
              case "text":
                return <Editable object={block} />;
              case "input-text":
                return (
                  <MkTextField
                    id={block.id}
                    key={block.id}
                    control={control}
                    {...block.props}
                  />
                );
              case "input-radio":
                return <RadioGroup key={block.id} block={block} />;
              case "input-checkbox":
                return (
                  <MkCheckbox
                    {...block.props}
                    id={block.id}
                    key={block.id}
                    control={control}
                  />
                );
              case "input-phone":
                return (
                  <MkPhoneInput
                    id={block.id}
                    key={block.id}
                    control={control}
                    {...block.props}
                  />
                );
              case "input-checkbox-group":
                return (
                  <MkCheckboxGroup
                    key={block.id}
                    control={control}
                    {...block.props}
                  />
                );
              case "button":
                return <MkButton build key={block.id} {...block.props} />;
              case "fetch":
                return (
                  <MkFetch
                    build
                    id={block.id}
                    key={block.id}
                    context={{ lead }}
                    control={control}
                    {...block.props}
                  />
                );
              case "input-address":
                return (
                  <MkAddress
                    id={block.id}
                    key={block.id}
                    control={control}
                    {...block.props}
                  />
                );
              case "input-address-full":
                return (
                  <MkAddressFull
                    id={block.id}
                    key={block.id}
                    control={control}
                    {...block.props}
                  />
                );
              case "html":
                return (
                  <MkHtml {...block.props} key={block.id} context={{ lead }} />
                );
              default:
                return <div key={block.id}>No matching block</div>;
            }
          })()}
        </div>
      ))}
    </>
  );
};

export default Step;
